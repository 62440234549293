<!--
 * @Author: liushuang
 * @Date: 2023-02-13 09:53:55
 * @LastEditors: liushuang
 * @Description: 
 * @FilePath: \Ats-web-enterprise\src\views\position\entryPostionCom.vue
-->
<template>
  <div :class="isMax?'isMax':'isMoadl'">
    <div class="center-title">
      <span>{{ title }}</span>
      <span v-if="!isMax">
      <svg @click.stop='minOperation' t="1675991873943" class="icon" viewBox="0 0 1024 1024" version="1.1"
           xmlns="http://www.w3.org/2000/svg" p-id="11850" width="22" height="22"><path
          d="M829.160727 880.360727L584.145455 635.345455a36.212364 36.212364 0 1 1 51.2-51.2l245.015272 245.015272a36.212364 36.212364 0 1 1-51.2 51.2z"
          fill="#A2A5B4" p-id="11851"></path><path
          d="M790.109091 612.864h-206.848a36.212364 36.212364 0 0 1 0-72.424727h206.848a36.212364 36.212364 0 0 1 0 72.424727z"
          fill="#A2A5B4" p-id="11852"></path><path
          d="M615.936 787.083636v-206.894545a36.212364 36.212364 0 0 0-72.424727 0v206.894545a36.212364 36.212364 0 0 0 72.424727 0z"
          fill="#A2A5B4" p-id="11853"></path><path
          d="M182.132364 127.860364l245.015272 245.015272a36.212364 36.212364 0 1 1-51.2 51.2L130.932364 179.060364a36.212364 36.212364 0 1 1 51.2-51.2z"
          fill="#A2A5B4" p-id="11854"></path><path
          d="M221.184 395.357091h206.848a36.212364 36.212364 0 0 1 0 72.424727H221.184a36.212364 36.212364 0 0 1 0-72.424727z"
          fill="#A2A5B4" p-id="11855"></path><path
          d="M395.357091 221.184v206.848a36.212364 36.212364 0 0 0 72.424727 0V221.184a36.212364 36.212364 0 0 0-72.424727 0z"
          fill="#A2A5B4" p-id="11856"></path></svg>
      <svg @click.stop='maxOperation' t="1675991924873" class="icon" viewBox="0 0 1024 1024" version="1.1"
           xmlns="http://www.w3.org/2000/svg" p-id="11993" width="22" height="22"><path
          d="M793.6 847.127273l-179.2-179.2a36.212364 36.212364 0 0 1 51.2-51.2l179.2 179.2a36.212364 36.212364 0 1 1-51.2 51.2z"
          fill="#A2A5B4" p-id="11994"></path><path
          d="M830.696727 655.825455v206.848a36.212364 36.212364 0 0 0 72.424728 0v-206.894546a36.212364 36.212364 0 0 0-72.424728 0z"
          fill="#A2A5B4" p-id="11995"></path><path
          d="M656.523636 829.998545h206.848a36.212364 36.212364 0 0 1 0 72.378182h-206.848a36.212364 36.212364 0 0 1 0-72.378182zM339.456 392.983273L193.163636 246.690909a36.212364 36.212364 0 1 1 51.2-51.2l146.292364 146.292364a36.212364 36.212364 0 0 1-51.2 51.2z"
          fill="#A2A5B4" p-id="11996"></path><path
          d="M370.874182 195.956364H164.026182a36.212364 36.212364 0 0 1 0-72.378182h206.848a36.212364 36.212364 0 0 1 0 72.378182z"
          fill="#A2A5B4" p-id="11997"></path><path
          d="M196.701091 370.176V163.281455a36.212364 36.212364 0 0 0-72.424727 0v206.894545a36.212364 36.212364 0 0 0 72.424727 0z"
          fill="#A2A5B4" p-id="11998"></path><path
          d="M847.872 233.053091l-179.2 179.2a36.212364 36.212364 0 0 1-51.2-51.2l179.2-179.2a36.212364 36.212364 0 1 1 51.2 51.2z"
          fill="#A2A5B4" p-id="11999"></path><path
          d="M656.523636 195.956364h206.848a36.212364 36.212364 0 0 0 0-72.378182h-206.848a36.212364 36.212364 0 0 0 0 72.378182z"
          fill="#A2A5B4" p-id="12000"></path><path
          d="M830.696727 370.176V163.281455a36.212364 36.212364 0 0 1 72.424728 0v206.894545a36.212364 36.212364 0 0 1-72.424728 0z"
          fill="#A2A5B4" p-id="12001"></path><path
          d="M393.681455 687.243636l-146.245819 146.245819a36.212364 36.212364 0 1 1-51.2-51.2l146.245819-146.245819a36.212364 36.212364 0 0 1 51.2 51.2z"
          fill="#A2A5B4" p-id="12002"></path><path
          d="M196.701091 655.825455v206.848a36.212364 36.212364 0 0 1-72.424727 0v-206.894546a36.212364 36.212364 0 0 1 72.424727 0z"
          fill="#A2A5B4" p-id="12003"></path><path
          d="M370.874182 829.998545H164.026182a36.212364 36.212364 0 0 0 0 72.378182h206.848a36.212364 36.212364 0 0 0 0-72.378182z"
          fill="#A2A5B4" p-id="12004"></path></svg>
      <svg @click.stop='closeMoadl' t="1675991965229" class="icon" viewBox="0 0 1024 1024" version="1.1"
           xmlns="http://www.w3.org/2000/svg" p-id="12141" width="22" height="22"><path
          d="M230.385866 742.382499m25.606007-25.606007l460.776492-460.776492q25.606008-25.606008 51.212016 0l0 0q25.606008 25.606008 0 51.212016l-460.776492 460.776491q-25.606008 25.606008-51.212016 0l0 0q-25.606008-25.606008 0-51.212015Z"
          fill="#A2A5B4" p-id="12142"></path><path
          d="M716.801278 768.02142L255.991873 307.212016a36.212364 36.212364 0 0 1 51.212016-51.212016l460.776492 460.776492a36.212364 36.212364 0 0 1-51.212016 51.212015z"
          fill="#A2A5B4" p-id="12143"></path></svg>
    </span>
  </div>
  <div class="custormForm">
    <a-form 
      v-if="!loadingEdit" 
      :model="ruleForm" 
      :colon= false 
      :rules="rules" 
      ref='modalFormRef' 
      autocomplete="off"
      scrollToFirstError
    >
      <!-- 基本信息 -->
      <div class="custorm-base item-one-box" v-if="setItem.project_job_info.show">
<!--        <input type="hidden" id="PlugsVersionATS" value="0"/>-->
        <div class="custorm-add-title">
          <svg t="1676253408455" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3140" xmlns:xlink="http://www.w3.org/1999/xlink" width="22" height="22"><path d="M597.333333 149.333333c47.146667 0 85.333333 31.829333 85.333334 71.125334V298.666667h128a85.333333 85.333333 0 0 1 85.333333 85.333333v426.666667a85.333333 85.333333 0 0 1-85.333333 85.333333H213.333333a85.333333 85.333333 0 0 1-85.333333-85.333333V384a85.333333 85.333333 0 0 1 85.333333-85.333333h128V220.458667c0-39.253333 38.186667-71.125333 85.333334-71.125334h170.666666z m234.666667 426.666667h-640V810.666667a21.333333 21.333333 0 0 0 17.493333 20.992L213.333333 832h597.333334a21.333333 21.333333 0 0 0 20.992-17.493333L832 810.666667v-234.666667z m-21.333333-213.333333H213.333333a21.333333 21.333333 0 0 0-20.992 17.493333L192 384v128h640V384a21.333333 21.333333 0 0 0-17.493333-20.992L810.666667 362.666667zM597.333333 213.333333h-170.666666c-10.453333 0-19.2 5.034667-20.992 11.648l-0.341334 2.56V298.666667h213.333334V227.541333c0-6.954667-7.552-12.8-17.493334-13.994666L597.333333 213.333333z" fill="#333333" p-id="3141"></path></svg>
          <h3>基本信息</h3>
        </div>
        <div class="custorm-content addContent" id="baseInfo">
          <a-form-item 
            label="职位名称" 
            :name="['baseInfo', 'title']"
            v-if="setItem.name.show" 
            :rules="setItem.name.notNull?rules.title:[{ required: false }]"
            @mouseleave="mouseJobName()"
          >
            <a-input v-model:value="ruleForm.baseInfo.title" placeholder="请输入职位名称" maxlength="40" />
          </a-form-item>
          <a-form-item 
            class="item-txt" 
            label="用人部门" 
            :name="['baseInfo', 'recruitmentDepartment']" 
            :rules="rules.recruitmentDepartment"
          >
            <a-input 
              v-model:value="ruleForm.baseInfo.recruitmentDepartment" 
              placeholder="请输入用人部门" 
              maxlength="40"
            />
          </a-form-item>
          <!--<a-form-item
            label="所属客户"
            :name="['baseInfo', 'customerId']"
            :rules="rules.customerId"
          >
          <a-select
              v-model:value="ruleForm.baseInfo.customerId"
              placeholder="请选择所属客户"
              :getPopupContainer="
                triggerNode => {
                  return triggerNode.parentNode
                }"
              @select="companyNameChange"
            >
            <a-select
                v-model:value="ruleForm.baseInfo.customerId"
                placeholder="请选择所属客户"
                :getPopupContainer="
                  triggerNode => {
                    return triggerNode.parentNode
                  }"
                @select="companyNameChange"
              >
                <template #suffixIcon><caret-down-outlined class="ant-select-suffix"/></template>
                <a-select-option v-for="item in clientSelect" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>-->
          <a-form-item
              class="item-txt"
              label="招聘数量"
              :name="['baseInfo', 'personNum']"
              v-if="setItem.recruitNum.show"
              :rules="setItem.recruitNum.notNull ? rules.personNum : [{ required: false }]"
          >
            <a-input
                v-model:value="ruleForm.baseInfo.personNum"
                placeholder="请输入招聘数量"
                maxlength="4"
                @input="restrictInput"
            />
            <span class="right_text">人</span>
          </a-form-item>
          <a-form-item
              label="工作城市"
              :name="['baseInfo', 'cityCodes']"
              v-if="setItem.city.show"
              :rules="setItem.city.notNull?rules.cityCodes:[{ required: false }]"
          >
            <city-list
                :selectNum='3'
                :oldSelData="ruleForm.baseInfo.cities"
                @searchCityhandle="searchCityhandle"
            ></city-list>
          </a-form-item>
          <a-form-item
              label="职位类别"
              :name="['baseInfo', 'professionCategory']"
              v-if="setItem.professionCategory.show"
              :rules="setItem.professionCategory.notNull?rules.professionCategory:[{ required: false }]"
          >
            <job-category
                :selecNum='1'
                :oldJobCategory="ruleForm.baseInfo.professionCategory"
                @searchJobTypehandle="searchTypehandle"
            ></job-category>
          </a-form-item>
          <a-form-item
              label="学历要求"
              :name="['baseInfo', 'educationType']"
              v-if="setItem.educationalRequirements.show"
              :rules="setItem.educationalRequirements.notNull?rules.educationType:[{ required: false }]"
          >
            <a-select
                v-model:value="ruleForm.baseInfo.educationType"
                placeholder="请选择学历要求"
                :options="educationTypeSelect"
                :field-names="{ label: 'dicValue', value: 'dicKey' }"
                :getPopupContainer="
                triggerNode => {
                  return triggerNode.parentNode
              }"
            >
              <template #suffixIcon>
                <caret-down-outlined class="ant-select-suffix"/>
              </template>
            </a-select>
          </a-form-item>
          <a-form-item
              class="tow-item-box"
              v-if="setItem.salary.show"
          >
            <template #label><span class="item-xing" v-if="setItem.salary.notNull">*</span>职位年薪</template>
            <div class="peer-wrap">
              <a-form-item
                  ref="salaryMinRef"
                  :name="['baseInfo', 'salaryMin']"
                  :rules="setItem.salary.notNull || ruleForm.baseInfo.salaryMin? rules.salaryMin:[{ required: false }]"
              >
                <a-input
                    v-model:value="ruleForm.baseInfo.salaryMin"
                    placeholder="最低年薪"
                    maxlength="6"
                    @input="salarySpceMin"
                />
                <span class="right_text">万</span>
              </a-form-item>
              <span class="center_text">-</span>
              <a-form-item
                  ref="salaryMaxRef"
                  :name="['baseInfo', 'salaryMax']"
                  :rules="setItem.salary.notNull || ruleForm.baseInfo.salaryMax?rules.salaryMax:[{ required: false }]"
              >
                <a-input
                    v-model:value="ruleForm.baseInfo.salaryMax"
                    placeholder="最高年薪"
                    maxlength="6"
                    @input="salarySpceMax"
                />
                <span class="right_text">万</span>
              </a-form-item>
            </div>
          </a-form-item>
          <a-form-item
              class="tow-item-box"
              v-if="setItem.experience.show"
          >
            <template #label><span class="item-xing" v-if="setItem.experience.notNull">*</span>经验要求</template>
            <div class="peer-wrap">
              <a-form-item
                  ref="experienceMinRef"
                  :name="['baseInfo', 'experienceMin']"
                  :rules="setItem.experience.notNull || ruleForm.baseInfo.experienceMin?rules.experienceMin:[{ required: false }]"
              >
                <a-input
                    v-model:value="ruleForm.baseInfo.experienceMin"
                    oninput="value=value.replace(/^\.+|[^\d]+/g,'')"
                    maxlength="3"
                    placeholder="最低经验"/>
                <span class="right_text">年</span>
              </a-form-item>
              <span class="center_text">-</span>
              <a-form-item
                  ref="experienceMaxRef"
                  :name="['baseInfo', 'experienceMax']"
                  :rules="setItem.experience.notNull || ruleForm.baseInfo.experienceMax?rules.experienceMax:[{ required: false }]"
              >
                <a-input
                    v-model:value="ruleForm.baseInfo.experienceMax"
                    oninput="value=value.replace(/^\.+|[^\d]+/g,'')"
                    maxlength="3"
                    placeholder="最高经验"/>
                <span class="right_text">年</span>
              </a-form-item>
            </div>
          </a-form-item>
          <a-form-item
              class="tow-item-box"
              v-if="setItem.age.show"
          >
            <template #label><span class="item-xing" v-if="setItem.age.notNull">*</span>年龄要求</template>
            <div class="peer-wrap">
              <a-form-item
                  ref="ageMinRef"
                  :name="['baseInfo', 'ageMin']"
                  :rules="setItem.age.notNull || !validater.isEmpty(ruleForm.baseInfo.ageMin)?rules.ageMin:[{ required: false }]"
              >
                <a-input
                    v-model:value="ruleForm.baseInfo.ageMin"
                    oninput="value=value.replace(/^\.+|[^\d]+/g,'')"
                    maxlength="3"
                    placeholder="最低年龄"/>
                <span class="right_text">岁</span>
              </a-form-item>
              <span class="center_text">-</span>
              <a-form-item
                  ref="ageMaxRef"
                  :name="['baseInfo', 'ageMax']"
                  :rules="setItem.age.notNull || ruleForm.baseInfo.ageMax?rules.ageMax:[{ required: false }]"
              >
                <a-input
                    v-model:value="ruleForm.baseInfo.ageMax"
                    maxlength="3"
                    oninput="value=value.replace(/^\.+|[^\d]+/g,'')"
                    placeholder="最高年龄"/>
                <span class="right_text">岁</span>
              </a-form-item>
            </div>
          </a-form-item>
        </div>
      </div>
      <!-- 项目信息 -->
      <div class="custorm-base item-tow-box" v-if="setItem.project_project_info.show">
        <div class="custorm-add-title">
          <svg t="1676259520979" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                p-id="3275" xmlns:xlink="http://www.w3.org/1999/xlink" width="22" height="22">
            <path d="M448 288m32 0l213.333333 0q32 0 32 32l0 0q0 32-32 32l-213.333333 0q-32 0-32-32l0 0q0-32 32-32Z"
                  fill="#333333" p-id="3276"></path>
            <path d="M448 480m32 0l213.333333 0q32 0 32 32l0 0q0 32-32 32l-213.333333 0q-32 0-32-32l0 0q0-32 32-32Z"
                  fill="#333333" p-id="3277"></path>
            <path d="M448 672m32 0l213.333333 0q32 0 32 32l0 0q0 32-32 32l-213.333333 0q-32 0-32-32l0 0q0-32 32-32Z"
                  fill="#333333" p-id="3278"></path>
            <path
                d="M362.666667 320m-42.666667 0a42.666667 42.666667 0 1 0 85.333333 0 42.666667 42.666667 0 1 0-85.333333 0Z"
                fill="#333333" p-id="3279"></path>
            <path
                d="M362.666667 512m-42.666667 0a42.666667 42.666667 0 1 0 85.333333 0 42.666667 42.666667 0 1 0-85.333333 0Z"
                fill="#333333" p-id="3280"></path>
            <path
                d="M362.666667 704m-42.666667 0a42.666667 42.666667 0 1 0 85.333333 0 42.666667 42.666667 0 1 0-85.333333 0Z"
                fill="#333333" p-id="3281"></path>
            <path
                d="M794.069333 85.333333C838.570667 85.333333 874.666667 120.064 874.666667 162.901333v698.197334C874.666667 903.936 838.570667 938.666667 794.069333 938.666667H229.973333C185.429333 938.666667 149.333333 903.936 149.333333 861.098667V162.901333C149.333333 120.064 185.429333 85.333333 229.930667 85.333333H794.026667z m0 58.197334H229.973333a19.968 19.968 0 0 0-19.84 15.872l-0.298666 3.498666v698.197334a19.626667 19.626667 0 0 0 16.512 19.072l3.626666 0.298666H794.026667c9.898667 0 18.133333-6.826667 19.84-15.872l0.298666-3.498666V162.901333a19.626667 19.626667 0 0 0-16.512-19.072l-3.626666-0.298666z"
                fill="#333333" p-id="3282"></path>
          </svg>
          <h3>项目信息</h3>
        </div>
        <div class="custorm-content addContent" id="extraInfo">
          <a-form-item
              :name="['extraInfo', 'priority']"
              v-if="setItem.priority.show"
              :rules="setItem.priority.notNull?rules.priority:[{ required: false }]"
          >
            <template #label>紧急程度</template>
            <a-select
                v-model:value="ruleForm.extraInfo.priority"
                placeholder="请选择紧急程度"
                :options="prioritySelect"
                :field-names="{ label: 'dicValue', value: 'dicKey' }"
                :getPopupContainer="
              triggerNode => {
                return triggerNode.parentNode
            }"
            >
              <template #suffixIcon>
                <caret-down-outlined class="ant-select-suffix"/>
              </template>
            </a-select>
          </a-form-item>
          <a-form-item
              label="职位状态"
              :name="['extraInfo', 'projectStatus']"
              v-if="setItem.status.show"
              :rules="setItem.status.notNull?rules.projectStatus:[{ required: false }]"
          >
            <a-select
                v-model:value="ruleForm.extraInfo.projectStatus"
                placeholder="请选择职位状态"
                :options="statusSelect"
                :field-names="{ label: 'dicValue', value: 'dicKey' }"
                :getPopupContainer="
              triggerNode => {
                return triggerNode.parentNode
            }"
            >
              <template #suffixIcon>
                <caret-down-outlined class="ant-select-suffix"/>
              </template>
            </a-select>
          </a-form-item>
          <!--          <a-form-item
                      :name="['extraInfo', 'customerContactsId']"
                      class="item-add-five-box"
                      v-if="setItem.clientContact.show && ruleForm.baseInfo.customerId"
                      :rules="setItem.clientContact.notNull?rules.customerContactsId:[{ required: false }]"
                    >
                      <template #label>客户联系人</template>
                      <a-select
                        mode="multiple"
                        v-model:value="ruleForm.extraInfo.customerContactsId"
                        option-label-prop="label"
                        placeholder="请选择客户联系人"
                        :getPopupContainer="
                          triggerNode => {
                            return triggerNode.parentNode
                        }"
                      >
                        <template #suffixIcon ><caret-down-outlined class="ant-select-suffix"/></template>
                        <a-select-option
                          v-for="item1 in contactsSelect"
                          :key="item1.id"
                          :value="item1.id"
                          :label="item1.name"
                          option-label-prop="label"
                        >{{ item1.name }}<span class="icon-status" v-if="item1.valid == 0">(已失效)</span></a-select-option>
                        <template #dropdownRender="{ menuNode: menu }">
                          <v-nodes :vnodes="menu" />
                          <a-divider style="margin: 4px 0" />
                          <div
                            class="add-custer-link"
                            style="padding: 4px 8px; cursor: pointer; margin-bottom: -8px;"
                            @mousedown="e => e.preventDefault()"
                            @click="addItem"
                          >
                            <plus-outlined />
                            新增
                          </div>
                        </template>
                      </a-select>
                    </a-form-item>-->
          <!--          <a-form-item
                      class="tow-item-box item-add-five-box"
                      v-if="setItem.participator.show"
                    >
                      <template #label><span class="item-xing" v-if="setItem.participator.notNull">*</span>项目参与者</template>
                      <div
                        class="participant-warp"
                        v-for="(partInfo, index) in ruleForm.extraInfo.participatorsOld"
                        :key='index'
                      >
                        <a-form-item
                          class="item-width-box"
                          :name="['extraInfo', 'participatorsOld', index, 'positionId']"
                          :rules="setItem.participator.notNull ? rules.positionId : [{ required: false }]"
                        >
                          <a-select
                            v-model:value="partInfo.positionId"
                            @select="value => searchpositionName(value, index)"
                            :getPopupContainer="
                              triggerNode => {
                                return triggerNode.parentNode
                            }"
                            placeholder="请选择"
                          >
                            <a-select-option
                            v-for="item2 in participantTypeSelect"
                            :key="item2.id"
                            :value="item2.id">{{ item2.name }}</a-select-option>
                            <template #suffixIcon><caret-down-outlined class="ant-select-suffix"/></template>
                          </a-select>
                        </a-form-item>
                        <a-form-item
                          class="select-center-box"
                          :class="index+1 !== ruleForm.extraInfo.participatorsOld.length ? 'select-conter-box1' : ''"
                          :name="['extraInfo','participatorsOld' , index, 'userId']"
                          :rules="setItem.participator.notNull ? rules.userId : [{ required: false }]"
                        >
                          <a-select
                            v-model:value="partInfo.userId"
                            show-search
                            placeholder="请输入"
                            :filter-option="filterOption"
                            option-label-prop="label"
                            :getPopupContainer="
                              triggerNode => {
                                return triggerNode.parentNode
                            }"
                            @click="getParticipantName(ruleForm.extraInfo.participatorsOld[index].positionId, '')"
                          >
                            <a-select-option
                              v-for="item1 in participantNameSelect"
                              :key="item1.id"
                              :value="item1.id"
                              :label="item1.name"
                            ><img class="img-phone" :src="item1.headPhoto" />{{ item1.name }}<span class="icon-status" v-if="item1.accountStatus == 0">(已关闭)</span><span class="icon-status" v-if="item1.staffStatus == 0">(已离职)</span></a-select-option>
                            <template #suffixIcon>
                              <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                  <title>icon/搜索</title>
                                  <g id="icon/搜索" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                      <path d="M10.4172942,9.60068923 C10.4466684,9.62125122 10.4754552,9.64416315 10.5013046,9.6711875 L13.8288229,12.9981183 C14.0526549,13.2219503 14.0561798,13.5973536 13.8270604,13.8264729 C13.7176586,13.9371034 13.5686661,13.9995542 13.4130779,14 C13.2574898,14.0004364 13.1081456,13.9388313 12.9981183,13.8288229 L9.67060001,10.5013046 C9.64486557,10.4755763 9.62165201,10.4474447 9.6012767,10.4172942 C8.55161259,11.2807959 7.23405595,11.7519008 5.87485573,11.7497114 C2.63017291,11.7497114 0,9.11953854 0,5.87485573 C0,2.63017291 2.63017291,0 5.87485573,0 C9.11953854,0 11.7497115,2.63017291 11.7497114,5.87485573 C11.7497114,7.2889335 11.2503487,8.58668912 10.4172942,9.60068923 Z M5.87485573,10.5747403 C8.47053031,10.5747403 10.5747403,8.4705303 10.5747403,5.87485572 C10.5747403,3.27918114 8.47053031,1.17497114 5.87485573,1.17497114 C3.27918115,1.17497114 1.17497115,3.27918114 1.17497115,5.87485572 C1.17497115,8.4705303 3.27918115,10.5747403 5.87485573,10.5747403 Z" id="形状" fill="#A2A5B4" fill-rule="nonzero"></path>
                                  </g>
                              </svg>
                            </template>
                          </a-select>
                        </a-form-item>
                          <svg width="22px" height="22px" v-if="index+1 == ruleForm.extraInfo.participatorsOld.length" @click="addShare" class="icon icon-add" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                              <title>icon/添加</title>
                              <g id="icon/添加" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                  <rect id="矩形" fill="#D8D8D8" opacity="0" x="0" y="0" width="22" height="22"></rect>
                                  <g class="blur-svg" id="添加" transform="translate(2.000000, 2.000000)" fill="#A2A5B4">
                                      <path d="M9,0 C13.9705627,0 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 Z M9,1.5 C4.85786438,1.5 1.5,4.85786438 1.5,9 C1.5,13.1421356 4.85786438,16.5 9,16.5 C13.1421356,16.5 16.5,13.1421356 16.5,9 C16.5,4.85786438 13.1421356,1.5 9,1.5 Z" id="椭圆形" fill-rule="nonzero"></path>
                                      <rect id="矩形" x="4.5" y="8.25" width="9" height="1.5" rx="0.75"></rect>
                                      <rect id="矩形备份-37" transform="translate(9.000000, 9.000000) rotate(-90.000000) translate(-9.000000, -9.000000) " x="4.5" y="8.25" width="9" height="1.5" rx="0.75"></rect>
                                  </g>
                              </g>
                          </svg>
                          <svg width="22px" height="22px" @click="deleteShare(index)" class="icon icon-del" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                              <title>icon/关闭弹窗</title>
                              <g id="icon/关闭弹窗" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                  <g id="编组-19">
                                      <rect id="矩形" fill="#D8D8D8" opacity="0" x="1.65934391" y="1.66971481" width="18.667619" height="18.667619"></rect>
                                      <g id="编组-17" class="blur-svg" transform="translate(11.000000, 11.000000) rotate(-45.000000) translate(-11.000000, -11.000000) translate(3.221825, 3.221825)" fill="#A2A5B4">
                                          <rect id="矩形备份-38" x="0" y="7.00035713" width="15.5563492" height="1.55563492" rx="0.777817459"></rect>
                                          <path d="M0.777817459,7.00035713 L14.7785317,7.00035713 C15.2081084,7.00035713 15.5563492,7.34859787 15.5563492,7.77817459 C15.5563492,8.20775131 15.2081084,8.55599205 14.7785317,8.55599205 L0.777817459,8.55599205 C0.348240738,8.55599205 -1.8189368e-12,8.20775131 -1.8189894e-12,7.77817459 C-1.81904201e-12,7.34859787 0.348240738,7.00035713 0.777817459,7.00035713 Z" id="矩形备份-39" transform="translate(7.778175, 7.778175) rotate(-90.000000) translate(-7.778175, -7.778175) "></path>
                                      </g>
                                  </g>
                              </g>
                          </svg>
                      </div>
                      <div v-if="ruleForm.extraInfo.participatorsOld.length == 0" class="participant-warp1">
                        <svg width="22px" height="22px" @click="addShare" class="icon icon-add" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>icon/添加</title>
                            <g id="icon/添加" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect id="矩形" fill="#D8D8D8" opacity="0" x="0" y="0" width="22" height="22"></rect>
                                <g id="添加" transform="translate(2.000000, 2.000000)" fill="#A2A5B4">
                                    <path d="M9,0 C13.9705627,0 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 Z M9,1.5 C4.85786438,1.5 1.5,4.85786438 1.5,9 C1.5,13.1421356 4.85786438,16.5 9,16.5 C13.1421356,16.5 16.5,13.1421356 16.5,9 C16.5,4.85786438 13.1421356,1.5 9,1.5 Z" id="椭圆形" fill-rule="nonzero"></path>
                                    <rect id="矩形" x="4.5" y="8.25" width="9" height="1.5" rx="0.75"></rect>
                                    <rect id="矩形备份-37" transform="translate(9.000000, 9.000000) rotate(-90.000000) translate(-9.000000, -9.000000) " x="4.5" y="8.25" width="9" height="1.5" rx="0.75"></rect>
                                </g>
                            </g>
                        </svg>
                      </div>
                    </a-form-item>-->
        </div>
      </div>
      <!-- 详细信息 -->
      <div class="custorm-base item-three-box" v-if="setItem.project_job_detail.show">
        <div class="custorm-add-title">
          <svg t="1676259947615" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3416" xmlns:xlink="http://www.w3.org/1999/xlink" width="22" height="22"><path d="M448 277.333333a42.666667 42.666667 0 0 1 42.666667 42.666667v128a42.666667 42.666667 0 0 1-42.666667 42.666667H320a42.666667 42.666667 0 0 1-42.666667-42.666667v-128a42.666667 42.666667 0 0 1 42.666667-42.666667h128zM426.666667 341.333333h-85.333334v85.333334h85.333334V341.333333z" fill="#333333" p-id="3417"></path><path d="M789.333333 128a85.333333 85.333333 0 0 1 85.333334 85.333333v597.333334a85.333333 85.333333 0 0 1-85.333334 85.333333H234.666667a85.333333 85.333333 0 0 1-85.333334-85.333333V213.333333a85.333333 85.333333 0 0 1 85.333334-85.333333h554.666666z m0 64H234.666667a21.333333 21.333333 0 0 0-20.992 17.493333L213.333333 213.333333v597.333334a21.333333 21.333333 0 0 0 17.493334 20.992L234.666667 832h554.666666a21.333333 21.333333 0 0 0 20.992-17.493333L810.666667 810.666667V213.333333a21.333333 21.333333 0 0 0-17.493334-20.992L789.333333 192z" fill="#333333" p-id="3418"></path><path d="M554.666667 352m32 0l149.333333 0q32 0 32 32l0 0q0 32-32 32l-149.333333 0q-32 0-32-32l0 0q0-32 32-32Z" fill="#333333" p-id="3419"></path><path d="M277.333333 554.666667m32 0l426.666667 0q32 0 32 32l0 0q0 32-32 32l-426.666667 0q-32 0-32-32l0 0q0-32 32-32Z" fill="#333333" p-id="3420"></path><path d="M277.333333 682.666667m32 0l426.666667 0q32 0 32 32l0 0q0 32-32 32l-426.666667 0q-32 0-32-32l0 0q0-32 32-32Z" fill="#333333" p-id="3421"></path></svg>
          <h3>详细信息</h3>
          <!-- AI生成-->
          <a-popover
            overlayClassName="popover-wrap popover-ai-box"
            placement="rightBottom"
            v-model:visible="aiPoShow"
            trigger="click"
            @visibleChange ="visible => visibleChange(visible)"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          >
            <template #content>
              <div class="ai-wrap">
                <div class="ai-item">
                  <div class="ai-item-head">
                    <div class="custorm-title">
                      <i class="left-line"></i>
                      <h3 class="information_title">职位描述</h3>
                    </div>
                    <div class="close-btn">
                      <close-outlined @click.stop="closeMoadlAi"/>
                    </div>
                  </div>
                  <div class="ai-item-content">
                    <a-skeleton v-if="isaiJobDescribe" active/>
                    <template v-else>{{aiJobDescribe}}</template>
                  </div>
                  <div class="ai-item-box">
                    <div class="ai-item-btn" @click="projectDescriptionClick">直接调用</div>
                  </div>
                </div>
                <div class="ai-line"></div>
                <div class="ai-item">
                  <div>
                    <div class="custorm-title">
                      <i class="left-line"></i>
                      <h3 class="information_title">任职要求</h3>
                    </div>
                  </div>
                  <div class="ai-item-content">
                    <a-skeleton v-if="isaiJobask" active/>
                    <template v-else>{{aiJobask}}</template>
                  </div>
                  <div class="ai-item-box">
                    <div class="ai-item-btn" @click="projectRequireClick">直接调用</div>
                  </div>
                </div>
              </div>
            </template>
            <div class="ai-btn"><img src="../../assets/img/icon/jiqiren.png" class="ai-img ai-icon" alt="AI"><img src="../../assets/img/icon/jiqiren_hover.png" class="ai-img ai-icon-hover" alt="AI">AI生成</div>
          </a-popover>
        </div>
        <div class="custorm-content" id="description">
          <a-form-item 
            label="职位描述" 
            :name="['detailInfo', 'projectDescription']"
            v-if="setItem.description.show"
            :rules="setItem.description.notNull?rules.projectDescription:[{ required: false }]"
          >
            <a-textarea
              v-model:value="ruleForm.detailInfo.projectDescription"
              placeholder="请填写职位描述"
              maxlength="5000"
              :auto-size="{ minRows: 2, maxRows: 5 }"
            />
          </a-form-item>
          <a-form-item 
            label="任职要求" 
            :name="['detailInfo', 'projectRequire']"
            v-if="setItem.requirements.show"
            :rules="setItem.requirements.notNull?rules.requirement:[{ required: false }]"
          >
            <a-textarea
              v-model:value="ruleForm.detailInfo.projectRequire"
              placeholder="请填写任职要求"
              maxlength="5000"
              :auto-size="{ minRows: 2, maxRows: 5 }"
            />
          </a-form-item>
          <a-form-item 
            label="面试流程" 
            :name="['detailInfo', 'interviewProcess']" 
            v-if="setItem.interviewProcess.show"
            :rules="setItem.interviewProcess.notNull?rules.interviewProcess:[{ required: false }]"
          >
            <a-textarea
              v-model:value="ruleForm.detailInfo.interviewProcess"
              placeholder="请填写面试流程"
              maxlength="5000"
              :auto-size="{ minRows: 2, maxRows: 5 }"
            />
          </a-form-item>
<!--          <a-form-item
            class="upload-box" 
            :name="['detailInfo', 'Importance']" 
            v-if="setItem.attachment.show"
            :rules="setItem.attachment.notNull?rules.Importance:[{ required: false }]"
          >
            <template #label>附<i class="space-font"/>件</template>
            <appendix-upload
              :oldFileList="ruleForm.detailInfo.attachments"
              :fileTips="uploadType"
              :fileSize="uploadSize"
              :uploadMaxNum="uploadNum"
              @fileUploadM="fileUploadM"
              @rightChaClick="rightChaClick"
            ></appendix-upload>
          </a-form-item>-->
        </div>
      </div>
      <!-- 同步发布到其他招聘网站 -->
      <div class="custorm-base item-tow-box" v-if="!newData.id">
        <div class="custorm-add-title">
          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>编组 22</title>
              <g id="改0206" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="录入职位0221" transform="translate(-189.000000, -920.000000)">
                      <g id="详情信息备份" transform="translate(173.000000, 896.000000)">
                          <g id="编组-22" transform="translate(16.000000, 24.000000)">
                              <rect id="矩形" fill="#D8D8D8" opacity="0" x="0" y="0" width="24" height="24"></rect>
                              <g id="发布" transform="translate(3.000000, 3.000000)" fill="#333333" fill-rule="nonzero">
                                  <path d="M14.5916055,17.1538519 L9.60869084,14.7121104 C9.23706128,14.529694 9.08093289,14.0813454 9.26344918,13.7077216 C9.35140884,13.5297009 9.50533824,13.3934381 9.69225252,13.3275044 C9.88136578,13.2637686 10.085872,13.2747575 10.2661893,13.362669 L14.3519154,15.3670509 L16.1155066,3.29900149 L8.17274941,12.8242112 L8.17274941,17.2505546 C8.17274941,17.6637386 7.83630372,18 7.42289332,18 C7.00948292,18 6.67303723,17.6637386 6.67303723,17.2505546 L6.67303723,12.578059 C6.67303723,12.5384988 6.67523622,12.5011365 6.68183319,12.4615763 C6.69942513,12.3165223 6.75879789,12.1780617 6.85115554,12.0659746 L14.3343235,3.09021171 L2.33662605,9.31214724 L5.16452908,10.7780713 C5.53615864,10.9736744 5.68349107,11.4264185 5.50097477,11.8022401 C5.4174131,11.9758653 5.26568269,12.1077325 5.08316639,12.1714684 C4.9006501,12.2352042 4.70054188,12.2220175 4.52682155,12.1385016 L0.436697419,10.0110436 C0.280569025,9.92972544 0.157425503,9.79785821 0.0870577757,9.63741974 C-0.00529986601,9.46379456 -0.0250907892,9.25940035 0.032082989,9.07258844 C0.0892567672,8.88357874 0.218997264,8.72533806 0.394916581,8.633031 L16.8785566,0.0858366201 C16.9885062,0.0286941531 17.1116497,0 17.2347933,0 C17.6020248,-0.00647044189 17.9186796,0.252868446 17.9846493,0.613305545 C18.0088383,0.734183841 18.0044403,0.857259924 17.9714554,0.975940432 L15.6911012,16.5824273 C15.6427234,16.9208865 15.3766455,17.1714342 15.0555927,17.2197855 C14.8972653,17.2483568 14.73454,17.2263789 14.5916055,17.1538519 Z" id="路径"></path>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </svg>
          <h3>同步发布到其他招聘网站</h3>
        </div>
        <div class="custorm-content addContent" id="synchronous">
          <div class="synchronous-box">
            <!-- 智联招聘-->
            <div class="synchronous-item" @click="selectWaiBtn(1)">
              <div class="synchronous-item-icon" :class="waiWangflg.indexOf(1) != -1 && zhilianReleaseTxt=='未发布'&& zhilianLoginTxt=='已登录' ? 'synchronous-icon-no-duigou' : zhilianReleaseTxt=='已发布' ? 'synchronous-icon-duigou' : ''"></div>
              <div class="synchronous-item-one">
                <img class="synchronous-logo" src="../../assets/img/icon/zhilianzhaopin.png" alt="网站logo">
                <span class="synchronous-btn" :class="zhilianReleaseTxt=='未发布'?'synchronous-no-release':'synchronous-release'">{{ zhilianReleaseTxt }}</span>
              </div>
              <div class="synchronous-item-two">
                <div class="synchronous-name-left"><span class="synchronous-label">用户名：</span><span class="synchronous-txt">{{ zhilianUserName }}</span></div>
                <div class="synchronous-status-right"><span class="synchronous-label">状态：</span><span class="" :class="zhilianIsLogin ? 'synchronous-status-green' : 'synchronous-status-blur'">{{ zhilianLoginTxt }}</span></div>
              </div>
            </div>
            <!-- boss直聘-->
            <div class="synchronous-item" @click="selectWaiBtn(2)">
              <div class="synchronous-item-icon" :class="waiWangflg.indexOf(2) != -1 && zhipinReleaseTxt=='未发布'&& zhipinLoginTxt=='已登录' ? 'synchronous-icon-no-duigou' : zhipinReleaseTxt=='已发布' ? 'synchronous-icon-duigou' : ''"></div>
              <div class="synchronous-item-one">
                <img class="synchronous-logo1" src="../../assets/img/icon/bosszhipin.png" alt="网站logo">
                <span class="synchronous-btn" :class="zhipinReleaseTxt=='未发布'?'synchronous-no-release':'synchronous-release'">{{ zhipinReleaseTxt }}</span>
              </div>
              <div class="synchronous-item-two">
                <div class="synchronous-name-left"><span class="synchronous-label">用户名：</span><span class="synchronous-txt">{{ zhipinUserName }}</span></div>
                <div class="synchronous-status-right"><span class="synchronous-label">状态：</span><span class="" :class="zhipinIsLogin ? 'synchronous-status-green' : 'synchronous-status-blur'">{{ zhipinLoginTxt }}</span></div>
              </div>
            </div>
            <!-- 前程无忧-->
            <div class="synchronous-item" @click="selectWaiBtn(3)">
              <div class="synchronous-item-icon" 
              :class="waiWangflg.indexOf(3) != -1 && qianchengReleaseTxt=='未发布'&& qianchengLoginTxt=='已登录' ? 'synchronous-icon-no-duigou' : qianchengReleaseTxt=='已发布' ? 'synchronous-icon-duigou' : ''"></div>
              <div class="synchronous-item-one">
                <img class="synchronous-logo2" src="../../assets/img/icon/qianchengwuyou.png" alt="网站logo">
                <span class="synchronous-btn" :class="qianchengReleaseTxt=='未发布'?'synchronous-no-release':'synchronous-release'">{{ qianchengReleaseTxt }}</span>
              </div>
              <div class="synchronous-item-two">
                <div class="synchronous-name-left"><span class="synchronous-label">用户名：</span><span class="synchronous-txt">{{ qianchengUserName }}</span></div>
                <div class="synchronous-status-right"><span class="synchronous-label">状态：</span><span class="" :class="qianchengIsLogin ? 'synchronous-status-green' : 'synchronous-status-blur'">{{ qianchengLoginTxt }}</span></div>
              </div>
            </div>
            <!-- 脉脉-->
            <div class="synchronous-item" @click="selectWaiBtn(4)">
              <div class="synchronous-item-icon"
                   :class="waiWangflg.indexOf(4) != -1 && maimaiReleaseTxt=='未发布'&& maimaiLoginTxt=='已登录' ? 'synchronous-icon-no-duigou' : maimaiReleaseTxt=='已发布' ? 'synchronous-icon-duigou' : ''"></div>
              <div class="synchronous-item-one">
                <img class="synchronous-logo2" src="../../assets/img/icon/maimai.png" alt="网站logo">
                <span class="synchronous-btn" :class="maimaiReleaseTxt=='未发布'?'synchronous-no-release':'synchronous-release'">{{ maimaiReleaseTxt }}</span>
              </div>
              <div class="synchronous-item-two">
                <div class="synchronous-name-left"><span class="synchronous-label">用户名：</span><span class="synchronous-txt">{{ maimaiUserName }}</span></div>
                <div class="synchronous-status-right"><span class="synchronous-label">状态：</span><span class="" :class="maimaiIsLogin ? 'synchronous-status-green' : 'synchronous-status-blur'">{{ maimaiLoginTxt }}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 招聘途径 -->
      <div class="custorm-base item-tow-box">
        <div class="custorm-add-title">
          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>编组 21</title>
              <g id="改0206" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="录入职位0221" transform="translate(-189.000000, -1108.000000)">
                      <g id="详情信息备份-2" transform="translate(173.000000, 1083.000000)">
                          <g id="编组-21" transform="translate(16.000000, 25.000000)">
                              <rect id="矩形" fill="#D8D8D8" opacity="0" x="0" y="0" width="24" height="24"></rect>
                              <g id="招聘" transform="translate(2.000000, 4.000000)" fill="#333333">
                                  <path d="M18,0 C19.1045695,0 20,0.8954305 20,2 L20,14 C20,15.1045695 19.1045695,16 18,16 L2,16 C0.8954305,16 0,15.1045695 0,14 L0,2 C0,0.8954305 0.8954305,0 2,0 L18,0 Z M18,1.5 L2,1.5 C1.75454011,1.5 1.55039163,1.67687516 1.50805567,1.91012437 L1.5,2 L1.5,14 C1.5,14.2454599 1.67687516,14.4496084 1.91012437,14.4919443 L2,14.5 L18,14.5 C18.2454599,14.5 18.4496084,14.3231248 18.4919443,14.0898756 L18.5,14 L18.5,2 C18.5,1.75454011 18.3231248,1.55039163 18.0898756,1.50805567 L18,1.5 Z" id="矩形" fill-rule="nonzero"></path>
                                  <path d="M7,3.25 C8.51878306,3.25 9.75,4.48121694 9.75,6 C9.75,6.72549656 9.46905985,7.38537604 9.01003568,7.8767823 C10.0629017,8.55759252 10.909282,9.8169258 10.7403122,11.749886 L10.7130432,12.0012455 L4.45050602,11.9763415 L3.44964422,11.962024 L3.02972412,11.9512455 C3.02972412,9.69447712 3.88748793,8.44295336 4.93247626,7.80921585 C4.5069023,7.32752746 4.25,6.6937651 4.25,6 C4.25,4.48121694 5.48121694,3.25 7,3.25 Z M6.99999984,8.75 C5.93742103,8.75 5.1183968,9.2267363 4.74738348,10.3601185 L4.711,10.478 L7.03740154,10.4935333 L9.159,10.499 L9.12256252,10.3684503 C8.8345304,9.45032465 8.12275266,8.88383467 7.30534149,8.77089843 L7.14054507,8.75438876 L6.99999984,8.75 Z M7,4.75 C6.30964406,4.75 5.75,5.30964406 5.75,6 C5.75,6.69035594 6.30964406,7.25 7,7.25 C7.69035594,7.25 8.25,6.69035594 8.25,6 C8.25,5.30964406 7.69035594,4.75 7,4.75 Z" id="形状结合" fill-rule="nonzero"></path>
                                  <rect id="矩形" x="12" y="4.25" width="5" height="1.5" rx="0.75"></rect>
                                  <rect id="矩形备份" x="12" y="7.25" width="5" height="1.5" rx="0.75"></rect>
                                  <rect id="矩形备份-2" x="12" y="10.25" width="5" height="1.5" rx="0.75"></rect>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </svg>
          <h3>招聘途径</h3>
          <span class="recruit-tip">（必填）</span>
        </div>
        <div class="custorm-content addContent" id="synchronous">
          <div class="synchronous-box">
            <a-form-item
              class="form-item-2 form-item-left">
              <a-checkbox @change="recruitmentChannelChange" v-model:checked="ruleForm.recruitmentChannel.selfRecruitment">公司自主招聘</a-checkbox>
              <div v-if="isRecruitmentChannelError" class="ant-form-item-explain ant-form-item-explain-connected">
                <div role="alert" class="ant-form-item-explain-error">{{ recruitmentChannelErrorTxt }}</div>
              </div>
            </a-form-item>
            <a-form-item class="form-item-right">
              <a-checkbox @change="recruitmentChannelChange" v-model:checked="ruleForm.recruitmentChannel.entrustRecruitment">委托猎头公司招聘</a-checkbox>
              <a-form-item class="item-add-five-box item-add-five-box2">
                <a-select 
                  mode="multiple"
                  v-model:value="ruleForm.recruitmentChannel.entrustHunterCompanyIds" 
                  option-label-prop="label"
                  placeholder="请选择委托猎头公司"
                  @change="recruitmentChannelChange"
                  :getPopupContainer="
                    triggerNode => {
                      return triggerNode.parentNode
                  }"
                >
                  <template #suffixIcon><caret-down-outlined class="ant-select-suffix"/></template>
                  <a-select-option 
                    v-for="item1 in hunterCompanyList" 
                    :key="item1.id" 
                    :value="item1.id"
                    :label="item1.name"
                    option-label-prop="label"
                  >{{ item1.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-form-item>
          </div>
        </div>
      </div>

    </a-form>
  </div>
  <!-- 按钮 -->
  <div class="btn-item" >
    <a-button 
      class="mainBtn" 
      type="primary" 
      @click="handleSaveJob" 
      :loading='loading'
    >保存</a-button>
  </div>

    <!-- 添加客户联系人弹窗 -->
    <perMoadl
        v-if="isShowPer"
        :isShowMoadl='isShowPer'
        @closeMoadl="moadlShow('isShowPer', false)"
        :customerId='ruleForm.baseInfo.customerId'
        @saveCantactHandle="saveCantactHandle"/>

    <!-- 新增插件下载温馨提示-->
    <!-- 温馨提示弹窗-->
    <div class="plugins-warp" ref='delmodel'>
      <a-modal
        :getContainer = '()=>$refs.delmodel'
        centered
        :closable="false"
        :maskClosable="false"
        v-model:visible="isPluginsShow"
        title="温馨提示"
        wrapClassName="del-modal-wrap1"
      >
        <template #footer>
          <a-button key="submit" class="mainBtn" type="primary" @click="downloadPlugins">去下载</a-button>
        </template>
        <div class="tips-box-title">
          <div class="tips-box-left"><img class="icon-img" src="../../assets/img/icon/tuijianxuzhi.png" alt="提示" />温馨提示</div>
          <div class="tips-box-right"><svg @click.stop='closeMoadlTips' t="1675991965229" class="icon" viewBox="0 0 1024 1024" version="1.1"
           xmlns="http://www.w3.org/2000/svg" p-id="12141" width="22" height="22"><path
          d="M230.385866 742.382499m25.606007-25.606007l460.776492-460.776492q25.606008-25.606008 51.212016 0l0 0q25.606008 25.606008 0 51.212016l-460.776492 460.776491q-25.606008 25.606008-51.212016 0l0 0q-25.606008-25.606008 0-51.212015Z"
          fill="#A2A5B4" p-id="12142"></path><path
          d="M716.801278 768.02142L255.991873 307.212016a36.212364 36.212364 0 0 1 51.212016-51.212016l460.776492 460.776492a36.212364 36.212364 0 0 1-51.212016 51.212015z"
          fill="#A2A5B4" p-id="12143"></path></svg></div>
        </div>
        <p class="tips-box-txt">请下载安装系统“简历助手”，安装完成请刷新本页面</p>
      </a-modal>
    </div>

  </div>

</template>
<script>
import {defineComponent, reactive, toRefs, ref, onMounted, getCurrentInstance} from 'vue'
import {
  CaretDownOutlined,
  CloseOutlined,
  // PlusOutlined
} from '@ant-design/icons-vue';
import {validater} from "@/utils/validater.js"
import CityList from '@/components/search/city.vue'
import JobCategory from '@/components/search/jobCategory.vue'
// import AppendixUpload from '@/components/upload/appendix.vue'
import getAdd from "@/composables/add"
import perMoadl from "@/views/custorm/pers/perMoadl.vue"
import {message} from "ant-design-vue"
import {useRouter} from "vue-router"
import {getDicList,getDicValue} from '@/composables/base'
import {DD_JOBAREA} from "@/utils/cityMap";
import {ZHIPINJOBTITLE} from "@/utils/zhipinJobTitle";
import {ZHILIANJOBTITLE} from "@/utils/zhilianJobTitle";
import {JOB51JOBTITLE} from "@/utils/job51JobTitle";
import {
  fieldSettingsListApi, //查询字段设置列表
  selectCustomerContactsApi, //客户联系人下拉框
  projectDropdownListApi, //参与者-获取职位下拉框
  userSearchApi, //查询公司所有用户
  getQianchengTokenListAPI, //获取前程无忧token集合
} from '@/api/comm'
import {
  userProjectApi, //参与者-用户下拉框
  projectDetailApi, //职位-详情
  projectUpdataeApi, //职位-编辑
  projectSaveApi,  //职位-录入
  huntCompanyDropdownListApi,//当前用户所属集团所拥有的猎头公司下拉框
  //gaodeApi,
} from '@/api/position'
//import BMap from "core-js/internals/map-helpers";
import axios from "axios";
//import {setUser} from "@/utils/storage";
// import {
//   selectcustomerApi, //客户下拉框
// } from '@/api/customer'
export default defineComponent({
  components: {
    CaretDownOutlined,
    CloseOutlined,
    /*    PlusOutlined,*/
    CityList,
    JobCategory,
    // AppendixUpload,
    perMoadl,
    /*    VNodes: (_, { attrs }) => {
          return attrs.vnodes;
        },*/
  },
  props: {
    addPar: Object
  },
  setup(props) {
    const personNumVal = (rule, value) => {
      if (validater.isEmpty(value)) {
        return Promise.reject("请输入招聘数量");
      } else if (!validater.min(value, 0) || !validater.max(value, 9999)) {
        return Promise.reject("0-9999之间的整数");
      } else {
        return Promise.resolve()
      }
    }
    const salaryMinVal = (rule, value) => {
      let salaryMaxC = data.ruleForm.baseInfo.salaryMax;
      if (validater.isEmpty(value)) {
        return Promise.reject("请输入最低年薪");
      } else if (!validater.towDecimal(value)) {
        return Promise.reject("最多输入2位小数");
      } else if (!validater.min(value, 0) || !validater.max(value, 999)) {
        return Promise.reject("0-999之间的数");
      } else if (validater.minAnd(value, salaryMaxC) && !validater.isEmpty(salaryMaxC)) {
        return Promise.reject("不能大于最高年薪");
      } else if (!validater.min(value, salaryMaxC) && !validater.isEmpty(salaryMaxC)) {
        salaryMaxRef.value.clearValidate();
        return Promise.resolve()
      } else {
        return Promise.resolve()
      }
    }
    const salaryMaxVal = (rule, value) => {
      let salaryMinC = data.ruleForm.baseInfo.salaryMin;
      if (validater.isEmpty(value)) {
        return Promise.reject("请输入最高年薪");
      } else if (!validater.towDecimal(value)) {
        return Promise.reject("最多输入2位小数");
      } else if (!validater.min(value, 0) || !validater.max(value, 999)) {
        return Promise.reject("0-999之间的数");
      } else if (validater.maxAnd(value, salaryMinC) && !validater.isEmpty(salaryMinC)) {
        return Promise.reject("不能小于最低年薪");
      } else if (!validater.max(value, salaryMinC) && !validater.isEmpty(salaryMinC)) {
        salaryMinRef.value.clearValidate();
        return Promise.resolve()
      } else {
        return Promise.resolve()
      }
    }
    const experienceMinVal = (rule, value) => {
      let experienceMaxVal = data.ruleForm.baseInfo.experienceMax
      if (validater.isEmpty(value)) {
        return Promise.reject("请输入最小经验");
      } else if (!validater.min(value, 0) || !validater.max(value, 99)) {
        return Promise.reject("0-99之间的整数");
      } else if (validater.minAnd(value, experienceMaxVal) && !validater.isEmpty(experienceMaxVal)) {
        return Promise.reject("不能大于最大经验");
      } else if (!validater.min(value, experienceMaxVal) && !validater.isEmpty(experienceMaxVal)) {
        experienceMaxRef.value.clearValidate();
        return Promise.resolve()
      } else {
        return Promise.resolve()
      }
    }
    const experienceMaxVal = (rule, value) => {
      let experienceMinVal = data.ruleForm.baseInfo.experienceMin
      if (validater.isEmpty(value)) {
        return Promise.reject("请输入最大经验");
      } else if (!validater.min(value, 0) || !validater.max(value, 99)) {
        return Promise.reject("0-99之间的整数");
      } else if (validater.maxAnd(value, experienceMinVal) && !validater.isEmpty(experienceMinVal)) {
        return Promise.reject("不能小于最小经验");
      } else if (!validater.max(value, experienceMinVal) && !validater.isEmpty(experienceMinVal) && !validater.maxAnd(experienceMinVal, 0) && !validater.minAnd(experienceMinVal, 99)) {
        experienceMinRef.value.clearValidate();
        return Promise.resolve()
      } else {
        return Promise.resolve()
      }
    }
    const ageMinVal = (rule, value) => {
      let ageMaxC = data.ruleForm.baseInfo.ageMax;
      if (validater.isEmpty(value)) {
        return Promise.reject("请输入最小年龄");
      } else if (!validater.min(value, 18) || !validater.max(value, 100)) {
        return Promise.reject("18-100之间的整数");
      } else if (validater.minAnd(value, ageMaxC) && !validater.isEmpty(ageMaxC)) {
        return Promise.reject("不能大于最大年龄");
      } else if (!validater.min(value, ageMaxC) && !validater.isEmpty(ageMaxC)) {
        ageMaxRef.value.clearValidate();
        return Promise.resolve()
      } else {
        return Promise.resolve()
      }
    }
    const ageMaxVal = (rule, value) => {
      let ageMinVal = data.ruleForm.baseInfo.ageMin;
      if (validater.isEmpty(value)) {
        return Promise.reject("请输入最大年龄");
      } else if (!validater.min(value, 18) || !validater.max(value, 100)) {
        return Promise.reject("18-100之间的整数");
      } else if (validater.maxAnd(value, ageMinVal) && !validater.isEmpty(ageMinVal)) {
        return Promise.reject("不能小于最小年龄");
      } else if (!validater.max(value, ageMinVal) && !validater.isEmpty(ageMinVal) && !validater.maxAnd(ageMinVal, 18) && !validater.minAnd(ageMinVal, 100)) {
        ageMinRef.value.clearValidate();
        return Promise.resolve()
      } else {
        return Promise.resolve()
      }
    }
    const fileVal = () => {
      if (data.ruleForm.detailInfo.attachments.length > 0 || data.uploadFlie.length > 0) {
        return Promise.resolve()
      } else {
        return Promise.reject("请上传职位附件");
      }
    }
    const cityVal = () => {
      if (data.ruleForm.baseInfo.cities.length > 0) {
        return Promise.resolve()
      } else {
        return Promise.reject("请选择工作城市");
      }
    }
    const visibleChange = () => {
      data.isaiJobask = data.isaiJobDescribe = true;
      data.aiJobask = data.aiJobDescribe = '';
      //AIdoubao()
      AI()

    };
    const rules = {
      baseInfo: {
        title: [{ required: true, message: "请输入职位名称", trigger: 'blur' }],
        // customerId: [{ required: true, message: "请选择所属客户", trigger: 'change' }],
        recruitmentDepartment: [{ required: true, message: "请输入用人部门", trigger: 'blur' }],
        personNum: [{ required: true, trigger: 'blur', validator: personNumVal }],
        cityCodes: [{ required: true, trigger: 'blur', validator: cityVal }],
        professionCategory: [{ required: true, message: "请选择职位类别", trigger: 'blur' }],
        educationType: [{ required: true, message: "请选择学历要求", trigger: 'change' }],
        salaryMin: [{ required: true, trigger: 'blur', validator: salaryMinVal }],
        salaryMax: [{ required: true, trigger: 'blur', validator: salaryMaxVal }],
        experienceMin: [{ required: true, trigger: 'blur', validator: experienceMinVal }],
        experienceMax: [{ required: true, trigger: 'blur', validator: experienceMaxVal }],
        ageMin: [{ required: true, trigger: 'blur', validator: ageMinVal }],
        ageMax: [{ required: true, trigger: 'blur', validator: ageMaxVal }]
      },
      extraInfo: {
        priority: [{ required: true, message: "请选择紧急程度", trigger: 'change' }],
        projectStatus: [{ required: true, message: "请选择职位状态", trigger: 'change' }],
        customerContactsId: [{ required: true, message: "请选择客户联系人", trigger: 'change' }],
      },
      positionId: [{required: true, message: "请选择类型", trigger: 'change'}],
      userId: [{required: true, message: "请选择参与者", trigger: 'change'}],
      detailInfo: {
        projectDescription: [{required: true, message: "请填写职位描述", trigger: 'blur'}],
        projectRequire: [{required: true, message: "请填写任职要求", trigger: 'blur'}],
        interviewProcess: [{required: true, message: "请填写面试流程", trigger: 'blur'}],
        Importance: [{required: true, trigger: 'blur', validator: fileVal}]
      }
    }
    const data = reactive({
      isFull: false,
      isShowPer: false,
      loadingEdit: true,
      setItem: {
        age: {notNull: '', show: '', id: ''},
        attachment: {notNull: '', show: '', id: ''},
        city: {notNull: '', show: '', id: ''},
        // clientContact: {notNull: '', show: '', id: ''},
        description: {notNull: '', show: '', id: ''},
        educationalRequirements: {notNull: '', show: '', id: ''},
        experience: {notNull: '', show: '', id: ''},
        interviewProcess: {notNull: '', show: '', id: ''},
        name: {notNull: '', show: '', id: ''},
        // participator: {notNull: '', show: '', id: ''},
        priority: {notNull: '', show: '', id: ''},
        professionCategory: {notNull: '', show: '', id: ''},
        project_job_detail: {show: ''},
        project_job_info: {show: ''},
        project_project_info: {show: ''},
        recruitNum: {notNull: '', show: '', id: ''},
        requirements: {notNull: '', show: '', id: ''},
        salary: {notNull: '', show: '', id: ''},
        status: {notNull: '', show: '', id: ''},
      },
      ruleForm: {
        baseInfo: {
          title: '',
          ageMin: '',
          ageMax: '',
          recruitmentDepartment: '',
          cities: [],
          cityCodes: [],
          customerId: undefined,
          educationType: undefined,
          experienceMin: '',
          experienceMax: '',
          personNum: '',
          professionCategory: [],
          professionCategoryId: '',
          salaryMin: '',
          salaryMax: ''
        },
        extraInfo: {
          priority: undefined,
          projectStatus: undefined,
          customerContactsId: [],
          participatorsOld: [{
            positionId: undefined,
            userId: undefined,
            userName: '',
            positionName: '',
            headPhoto: '',
          }],
        },
        detailInfo: {
          projectDescription: '',
          projectRequire: '',
          interviewProcess: '',
          attachments: [] //接口返回附件
        },
        recruitmentChannel: {//招聘途径
          selfRecruitment: false,
          entrustRecruitment: false,
          entrustHunterCompanyIds: [],
          // entrustHunterCompanies: [],
        }
      },
      uploadFlie: [], //新上传文件
      uploadType: 'jpg/jpeg/png/pdf/doc/docx', //上传-格式
      uploadSize: 10, //上传-大小
      uploadNum: 5, //上传数量
      clientSelect: [], //客户数据
      educationTypeSelect: [], //学历要求数据
      prioritySelect: [], //紧急程度数据
      statusSelect: [], //项目进展
      contactsSelect: [], //客户联系人
      participantTypeSelect: [], //参与者类型
      participantNameSelect: [], //参与者

      path:'addPosition',
      id:'', //职位Id
      title:'职位',
      addPar:props.addPar,
      isAdd:0,
      aiPoShow: false,
      aiJobDescribe:'',
      isaiJobDescribe: true,
      aiJobask:'',
      isaiJobask: true,
      zhilianIsLogin: false,//智联是否登录
      zhilianLoginTxt: '未登录',//智联是否登录的文本
      zhilianUserName: '--',//智联用户名
      zhilianReleaseTxt: '未发布',//智联是否发布的文本
      zhipinIsLogin: false,//boss是否登录
      zhipinLoginTxt: '未登录',//boss是否登录的文本
      zhipinUserName: '--',//boss用户名
      zhipinReleaseTxt: '未发布',//boss是否发布的文本
      qianchengIsLogin: false,//前程无忧是否登录
      qianchengLoginTxt: '未登录',//前程无忧是否登录的文本
      qianchengUserName: '--',//前程无忧用户名
      qianchengReleaseTxt: '未发布',//前程无忧是否发布的文本
      maimaiIsLogin: false,//脉脉是否登录
      maimaiLoginTxt: '未登录',//脉脉是否登录的文本
      maimaiUserName: '--',//脉脉用户名
      maimaiReleaseTxt: '未发布',//脉脉是否发布的文本
      waiWangflg: [],//同步发布到其他招聘网站
      hunterCompanyList: [],
      isRecruitmentChannelError: false,
      recruitmentChannelErrorTxt: '请选择招聘途径',
      isPluginsShow: false, //是否安装插件
    })
    const router = useRouter();
    const $bus = getCurrentInstance().appContext.config.globalProperties.$bus
    const {
      isMax,
      closeMoadl,
      maxOperation,
      minOperation,
      newData,
    } = getAdd(data)
    const salaryMaxRef = ref();
    const salaryMinRef = ref();
    const experienceMinRef = ref();
    const experienceMaxRef = ref();
    const ageMinRef = ref();
    const ageMaxRef = ref();
    //输入校验
    const restrictInput = () => {
      data.ruleForm.baseInfo.personNum = validater.valZheng(data.ruleForm.baseInfo.personNum);
    }
    //最低年薪效验
    const salarySpceMin = () => {
      data.ruleForm.baseInfo.salaryMin = validater.checkNum2(data.ruleForm.baseInfo.salaryMin)
    }
    //最高年薪效验
    const salarySpceMax = () => {
      data.ruleForm.baseInfo.salaryMax = validater.checkNum2(data.ruleForm.baseInfo.salaryMax)
    }
    const mouseJobName = () => {
      getJobZhiPin()
      getJob51Job()
      getJobZhiLian()
      getJobMaiMai()
    }
    function hasPlugs() {
      let version =document.getElementById("PlugsVersionATS")==null?0:document.getElementById("PlugsVersionATS").value
      if (!version || Number(version) < 100) {
        return false
      } else {
        return true;
      }
    }

    onMounted(async () => {
      if (newData.customerId) {
        data.ruleForm.baseInfo.customerId = Number(newData.customerId);
        searchCompanyName(newData.customerId)
      }
      await fieldSettingsList();
      // await selectSubCompanyName();
      await projectDropdownList();
      data.educationTypeSelect = await getDicList ('EDU_LEVEL',data.setItem.educationalRequirements.notNull) //学历
      data.prioritySelect = await getDicList ('PRIORITY_LEVEL',data.setItem.priority.notNull) //紧急程度
      data.statusSelect = await getDicList ('PROJECT_STATUS',data.setItem.status.notNull) //项目状态
      if ((newData.id && data.isAdd != 1) || newData.fromDetailEdit == 1) {
        await getPositionDetails();
      } else {
        data.loadingEdit = false;
      }
      getHuntCompanyDropdownList()
      getUserInfo51Job()
      getUserInfoZhiLian()
      getUserInfoZhiPin()
      getUserInfoMaiMai()
    })
    //查询当前用户所属集团所拥有的猎头公司下拉框
    const getHuntCompanyDropdownList = () => {
      huntCompanyDropdownListApi().then((res) => {
        if (res.success) {
          data.hunterCompanyList = res.data;
        } else {
          message.waring(res.message);
        }
      })
    }
    //查询字段列表
    const fieldSettingsList = () => {
      let params = {
        module: 'PROJECT'
      }
      fieldSettingsListApi(params).then(res => {
        if (res.success) {
          data.setItem = res.data;
        } else {
          message.warning(res.message);
        }
      })
    }
    //获取客户下拉框数据
    // const selectSubCompanyName = () => {
    //   selectcustomerApi().then(res => {
    //     if (res.success) {
    //       data.clientSelect = res.data;
    //     } else {
    //       message.warning(res.message);
    //     }
    //   })
    // }
    //内容发生改变
    const companyNameChange = (value) => {
      data.ruleForm.extraInfo.customerContactsId = [];
      searchCompanyName(value);
    }
    //客户下拉框内容发生改变，带出客户联系人
    const searchCompanyName = (value) => {
      let params = {
        customerId: value
      }
      selectCustomerContactsApi(params).then(res => {
        if (res.success) {
          data.contactsSelect = res.data;
        } else {
          message.warning(res.message);
        }
      })
    }
    //新增联系人--弹窗展示
    const addItem = () => {
      data.isShowPer = true;
    }
    //新建联系人后处理- 传值
    const saveCantactHandle = (val) => {
      if (val) {
        searchCompanyName(data.ruleForm.baseInfo.customerId);
      }
    }
    //参与者-职位下拉框
    const projectDropdownList = () => {
      let param = {
        searchCondition: ''
      }
      projectDropdownListApi(param).then(res => {
        if (res.success) {
          data.participantTypeSelect = res.data;
        } else {
          message.warning(res.message);
        }
      })
    }
    //参与者-职位下拉框发生改变
    const searchpositionName = (value, index) => {
      getParticipantName(value, '')
      data.ruleForm.extraInfo.participatorsOld[index].userId = undefined;
    }
    //参与者-查询参与者用户
    const getParticipantName = (id, val) => {
      if (id) {
        let param = {
          searchCondition: val,
          positionId: id
        }
        if (newData.id) {
          param.projectId = newData.id;
        }
        userProjectApi(param).then(res => {
          if (res.success) {
            data.participantNameSelect = res.data;
          } else {
            message.warning(res.message);
          }
        })
      }
    }
    const filterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    //参与者-添加
    const addShare = () => {
      data.ruleForm.extraInfo.participatorsOld.push(
          {userId: undefined, userName: '', positionId: undefined, positionName: '', headPhoto: ''})
    }
    //参与者-删除
    const deleteShare = (index) => {
      data.ruleForm.extraInfo.participatorsOld.splice(index, 1)
    }
    //弹窗展示
    const moadlShow = () => {
      data.isShowPer = false;
    }
    //关闭ai详情信息弹窗
    const closeMoadlAi = ()=>{
      data.aiPoShow = false;
    };
    //关闭插件安装弹窗
    const closeMoadlTips = () => {
      data.isPluginsShow = false;
    }
    const modalFormRef = ref();
    const loading = ref(false);
    //保存职位信息
    const handleSaveJob = () => {
      modalFormRef.value.validateFields().then(() => {
        // if (data.setItem.participator.notNull && data.ruleForm.extraInfo.participatorsOld.length == 0) {
        //   message.warning('至少选择一位项目参与者')
        //   return
        // }
        if (!data.ruleForm.recruitmentChannel.selfRecruitment && !data.ruleForm.recruitmentChannel.entrustRecruitment) {
          data.isRecruitmentChannelError = true;
          data.recruitmentChannelErrorTxt = "请至少勾选一种招聘途径";
          return
        } else if (data.ruleForm.recruitmentChannel.entrustRecruitment && data.ruleForm.recruitmentChannel.entrustHunterCompanyIds.length == 0) {
          data.isRecruitmentChannelError = true;
          data.recruitmentChannelErrorTxt = "请选择委托猎头公司招聘";
          return
        } else {
          data.isRecruitmentChannelError = false;
        }
        //设置城市    
        if (data.ruleForm.baseInfo.cities.length > 0) {
          let codes = [];
          data.ruleForm.baseInfo.cities.forEach(item => {
            codes.push(item.value);
          })
          data.ruleForm.cityCodes = codes;
        }
        //设置职类
        if (!validater.isEmpty(data.ruleForm.baseInfo.professionCategory)) {
          if (data.ruleForm.baseInfo.professionCategory.length > 0) {
            data.ruleForm.baseInfo.professionCategoryId = data.ruleForm.baseInfo.professionCategory[0].value;
          }
        }
        //参与者
        // let userIdArr = [];
        // if (data.ruleForm.extraInfo.participatorsOld.length > 0) {
        //   data.ruleForm.extraInfo.participatorsOld.forEach(item => {
        //     if (!validater.isEmpty(item.userId)) {
        //       userIdArr.push(item.userId)
        //     }
        //   })
        // }

        // data.ruleForm.extraInfo.participators = userIdArr;
        data.ruleForm.detailInfo.attachments = [...data.ruleForm.detailInfo.attachments, ...data.uploadFlie]
        data.ruleForm.extraInfo.customerContactIds = data.ruleForm.extraInfo.customerContactsId;
        loading.value = true;
        if (newData.id) { //编辑
          data.ruleForm.projectId = newData.id;
          projectUpdataeApi(data.ruleForm).then(res => {
            if (res.success) {
              message.success('编辑成功')
              goToLink();
              $bus.emit("changePositionMenu", true);
              $bus.emit("changePositionList", true);
            } else {
              message.warning(res.message);
            }
            loading.value = false;
          })
        } else { //保存
          projectSaveApi(data.ruleForm).then(res => {
            if (res.success) {
              message.success('录入成功')
              goToLink();
              $bus.emit("changePositionMenu", true);
              $bus.emit("changePositionList", true);

              //同步招聘平台职位
              if (data.ruleForm.baseInfo.cities.length > 0) {
                JobCityInput = data.ruleForm.baseInfo.cities
                JobCityInput51 = data.ruleForm.baseInfo.cities
                for (var y = 0; y < JobCityInput.length; y++) {
                  if (JobCityInput[y].label.includes("省")) {
                    JobCityInput[y].label = JobCityInput[y].label.replace("省", '');
                  }
                }
              }


              getCitysZhiLian()
              getCitysZhiPin()
              getCitys51Job()
              //publishJobZhiLian()
              //publishJobJob51()
              //publishJobZhipin()
              if (data.waiWangflg.indexOf(1) != -1 && data.zhilianReleaseTxt == '未发布' && data.zhilianLoginTxt == '已登录') {
                publishJobZhiLian()
              }
              if (data.waiWangflg.indexOf(2) != -1 && data.zhipinReleaseTxt == '未发布' && data.zhipinLoginTxt == '已登录') {
                publishJobZhipin()
              }
              if (data.waiWangflg.indexOf(3) != -1 && data.qianchengReleaseTxt == '未发布' && data.qianchengLoginTxt == '已登录') {
                publishJobJob51()
              }
              if (data.waiWangflg.indexOf(4) != -1 && data.maimaiReleaseTxt == '未发布' && data.maimaiLoginTxt == '已登录') {
                publishJobMaiMai()
              }
            } else {
              message.warning(res.message);
            }
            loading.value = false;
          });
        }

      })
      .catch((rep) => {
        modalFormRef.value.scrollToField(rep.errorFields && rep.errorFields[0].name);//滚动到对应字段位置
        if (rep.errorFields[0].name.includes('baseInfo')) {
          message.warning('请补全基本信息')
        } else if (rep.errorFields[0].name.includes('extraInfo')) {
          message.warning('请补全项目信息')
        } else if (rep.errorFields[0].name.includes('detailInfo')) {
          message.warning('请补全详细信息')
        }
        recruitmentChannelVal();
      })
    }
    //关闭或跳转
    const goToLink = () => {
      if (isMax) { //大屏
        router.push({
          path: '/positions',
        });
      } else {
        closeMoadl();
      }
    }
    //招聘途径复选框勾选事件
    const recruitmentChannelChange = () => {
      recruitmentChannelVal();
    }
    //校验招聘途径是否勾选
    const recruitmentChannelVal = () => {
      if (!data.ruleForm.recruitmentChannel.selfRecruitment && !data.ruleForm.recruitmentChannel.entrustRecruitment) {
        data.isRecruitmentChannelError = true;
        data.recruitmentChannelErrorTxt = "请至少勾选一种招聘途径";
      } else if (data.ruleForm.recruitmentChannel.entrustRecruitment && data.ruleForm.recruitmentChannel.entrustHunterCompanyIds.length == 0) {
        data.isRecruitmentChannelError = true;
        data.recruitmentChannelErrorTxt = "请选择委托猎头公司招聘";
      } else {
        data.isRecruitmentChannelError = false;
      }
    }
    //查询职位详情
    const getPositionDetails = () => {
      projectDetailApi(newData.id).then(res => {
        data.loadingEdit = false;
        if (res.success) {
          data.ruleForm = res.data;
          //处理所属客户
          if (data.ruleForm.customerPermission !== 'CREATE_AND_EDIT_PROJECT') { //客户是否有编辑权限，并清空客户和客户联系人
            data.ruleForm.baseInfo.customer = {};
            data.ruleForm.extraInfo.customerContacts = {};
          } else {
            if (data.ruleForm.baseInfo.customer) {
              data.ruleForm.baseInfo.customerId = data.ruleForm.baseInfo.customer.id;
              searchCompanyName(data.ruleForm.baseInfo.customer.id)
            }
            //客户联系人
            if (data.ruleForm.extraInfo.customerContacts.length > 0) {
              data.ruleForm.extraInfo.customerContactsId = [];
              res.data.extraInfo.customerContacts.forEach(item => {
                data.ruleForm.extraInfo.customerContactsId.push(item.id);
              })
              // data.ruleForm.extraInfo.customerContactsId = data.ruleForm.extraInfo.customerContacts.id;
            }
          }
          //处理工作城市
          if (res.data.baseInfo.cities.length > 0) {
            let cityArr = [];
            let citys = [];
            data.ruleForm.baseInfo.cities.forEach(item => {
              cityArr.push(item.id);
              let obj = {
                value: item.id,
                label: item.name
              }
              citys.push(obj)
            })
            data.ruleForm.baseInfo.cities = citys;
            data.ruleForm.baseInfo.cityCodes = cityArr;
          }
          //职位类别
          if (res.data.baseInfo.professionCategory) {
            let obj = {
              label: res.data.baseInfo.professionCategory.name,
              value: res.data.baseInfo.professionCategory.id
            };
            data.ruleForm.baseInfo.professionCategory = [];
            data.ruleForm.baseInfo.professionCategory.push(obj)
          } else {
            res.data.baseInfo.professionCategory = [];
          }
          //参与者
          if (!data.ruleForm.extraInfo.participators) {
            addShare();
          } else {
            data.ruleForm.extraInfo.participatorsOld = data.ruleForm.extraInfo.participators;
            let params = {
              searchCondition: ''
            }
            userSearchApi('search-condition', params).then(res => {
              if (res.success) {
                data.participantNameSelect = res.data;
              } else {
                message.message(res.message)
              }
            })
          }
          //招聘途径
          if (res.data.recruitmentChannel.entrustHunterCompanies && res.data.recruitmentChannel.entrustHunterCompanies.length > 0) {
            data.ruleForm.recruitmentChannel.entrustHunterCompanyIds = [];
            res.data.recruitmentChannel.entrustHunterCompanies.forEach(item => {
              data.ruleForm.recruitmentChannel.entrustHunterCompanyIds.push(item.id);
            })
          }
        } else {
          message.warning(res.message)
        }
      })
    }
    //上传附件-子传值
    const fileUploadM = (arr, flg) => {
      if (flg == 1) { //进度条
        data.ruleForm.detailInfo.attachments.unshift(arr)
      } else if (flg == 2) {
        data.ruleForm.detailInfo.attachments.forEach(item => {
          if (item.uid == arr.uid) {
            item.attachmentUrl = arr.attachmentUrl
          }
        })
      }
    }
    //上传-删除后台文件
    const rightChaClick = (index) => {
      data.ruleForm.detailInfo.attachments.splice(index, 1);
    }
    //获取城市选择的值
    const searchCityhandle = (arr) => {
      data.ruleForm.baseInfo.cities = arr;
      data.ruleForm.baseInfo.cityCodes = [];
      if (arr.length > 0) {
        arr.forEach(item => {
          data.ruleForm.baseInfo.cityCodes.push(item.value)
        })
      }
    }
    //获取职位类别选择的值
    const searchTypehandle = (arr) => {
      data.ruleForm.baseInfo.professionCategory = arr;
    }
    //点击ai职位描述-直接调用
    const projectDescriptionClick = () => {
       data.ruleForm.detailInfo.projectDescription = data.aiJobDescribe
    }
    //点击ai任职要求-直接调用
    const projectRequireClick = () => {
       data.ruleForm.detailInfo.projectRequire = data.aiJobask
    }
    //去下载插件
    const downloadPlugins = () => {
      window.open('/static/简历助手V1.0.0.zip');
    }
    var zhilianchildArr = []
    var jOBCitys = []
    //var zhilianJobAddressList;
    // 初始化
    /**监听插件返回的查询结果，并对数据进行处理符合table展示 */
    window.addEventListener('message', function () {
      if (event.source !== window) {
        return;
      }
      var message = event.data;
      if (message.type != '__RISFOND_PLUGIN_RESPONSE__') {
        return;
      }
      var subType = message.subType;
      var response = message.response;
      document.getElementById("PlugsVersionATS").value=100
      if (subType == '51JOBUserInfo') {
        if(response.code=="Q0000"){
          data.qianchengIsLogin=false
          data.qianchengLoginTxt="未登录"
        }else if(response.code=="200"){
          data.qianchengIsLogin=true
          data.qianchengLoginTxt="已登录"
          data.qianchengUserName=response.data.login
        }
      }
      if (subType == 'zhilianUserInfo') {
        if(response.code==401){
          data.zhilianIsLogin=false
          data.zhilianLoginTxt="未登录"
        }else if(response.code==200){
          data.zhilianIsLogin=true
          data.zhilianLoginTxt="已登录"
          data.zhilianUserName=response.data.staff.staffName
        }
      }
      if (subType == 'zhipinUserInfo') {
        if(response.code==7){
          data.zhipinIsLogin=false
          data.zhipinLoginTxt="未登录"
        }else if(response.code==0){
          data.zhipinIsLogin=true
          data.zhipinLoginTxt="已登录"
          data.zhipinUserName=response.zpData.baseInfo.name
        }
      }
      if (subType == 'maimaiUserInfo') {
        if(response.data.cid==1){
          data.maimaiIsLogin=false
          data.maimaiLoginTxt="未登录"
        }else {
          data.maimaiIsLogin=true
          data.maimaiLoginTxt="已登录"
          data.maimaiUserName=response.data.ucard.name
          //userIdMaiMai=response.data.ucard.id
        }
        //data.loading = false;
      }
      if (subType == 'zhilianCitys') {

        for (var j = 0; j < response.data.length; j++) {
          for (var k = 0; k < response.data[j].children.length; k++) {
            for (var y = 0; y < JobCityInput.length; y++) {
              if (response.data[j].children[k].label === JobCityInput[y]) {
                zhilianchildArr.push(response.data[j].children[k].value);
              }
            }

          }
          for (var l = 0; l < JobCityInput.length; l++) {
            if (response.data[j].label === JobCityInput[l]) {
              zhilianchildArr.push(response.data[j].value);
            }
          }
        }
      }
      if (subType == 'zhipinCitys') {
        var zhipinchildArr = []
        for (var jj = 0; jj < response.zpData.cityList.length; jj++) {
          for (var kk = 0; kk < response.zpData.cityList[jj].subLevelModelList.length; kk++) {
            for (var v = 0; v < JobCityInput.length; v++) {
              if (response.zpData.cityList[jj].subLevelModelList[kk].name === JobCityInput[v]) {
                zhipinchildArr.push(response.zpData.cityList[jj].subLevelModelList[kk].code);
              }
            }
          }
          for (var f = 0; f < JobCityInput.length; f++) {
            if (response.zpData.cityList[jj].name === JobCityInput[f]) {
              zhipinchildArr.push(response.zpData.cityList[jj].code);
            }
          }
        }
      }
      if (subType == '51JOBCitys') {

        response = response.split("=").pop().trim();
        var responseJson = DD_JOBAREA
        for (var jjj = 0; jjj < responseJson.length; jjj++) {
          for (var kkk = 0; kkk < responseJson[jjj].sub.length; kkk++) {
            for (var vv = 0; vv < JobCityInput51.length; vv++) {
              if (responseJson[jjj].sub[kkk].c === JobCityInput51[vv]) {
                jOBCitys.push(responseJson[jjj].sub[kkk].id);
              }
            }
          }
        }
      }
      if (subType == 'zhipinJob') {
        if (response.code == 0) {
          for (var z = 0; z < response.zpData.onlineJobList.length; z++) {
            if (response.zpData.onlineJobList[z].jobName === data.ruleForm.baseInfo.title) {
              data.zhipinReleaseTxt="已发布";
              return;
            } else {
              data.zhipinReleaseTxt="未发布";
            }
          }
        }
      }
      if (subType == '51JOBJob') {
        if (response.code == "") {
          for (var zz = 0; zz < response.data.joblist.length; zz++) {
            if (response.data.joblist[zz].jobname === data.ruleForm.baseInfo.title) {
              data.qianchengReleaseTxt="已发布";
              return;
            } else {
              data.qianchengReleaseTxt="未发布";
            }
          }
        }

      }
      if (subType == 'zhilianJob') {
        if (response.code == 200) {
          for (var zzz = 0; zzz < response.data.length; zzz++) {
            if (response.data[zzz].jobTitle === data.ruleForm.baseInfo.title) {
              data.zhilianReleaseTxt="已发布";
              return;
            } else {
              data.zhilianReleaseTxt="未发布";
            }
          }
        }

      }
      /*if (subType == 'zhilianJobAddressList') {
        zhilianJobAddressList=response
      }*/
      if (subType == 'maimaiJob') {
        if (response.code == 0) {
          for (var zzzz = 0; zzzz < response.data.list.length; zzzz++) {
            if (response.data.list[zzzz].position === data.ruleForm.baseInfo.title) {
              data.maimaiReleaseTxt="已发布";
              return;
            } else {
              data.maimaiReleaseTxt="未发布";
            }
          }
        }
      }
    })
    //点击外网发布
    const selectWaiBtn = (flg) => {
      setTimeout(()=>{
        if(!hasPlugs()){
          data.isPluginsShow = true;
        }
      },1000)
      let index_ = data.waiWangflg.indexOf(flg);
      if (index_ == -1) {
        data.waiWangflg.push(flg);
      } else {
        data.waiWangflg.splice(index_, 1);
      }
      if (flg == 1) {//智联
        if(data.zhilianIsLogin==false&&hasPlugs()){
          window.open('https://passport.zhaopin.com/org/login', '_blank');
        }
      } else if (flg == 2) {//boss
        if(data.zhipinIsLogin==false&&hasPlugs()){
          window.open('https://login.zhipin.com/?ka=header-login', '_blank');
        }
      } else if (flg == 3) {//无忧
        if(data.qianchengIsLogin==false&&hasPlugs()){
          window.open('https://ehire.51job.com/', '_blank');
        }
      } else if (flg == 4) {//脉脉
        if(data.maimaiIsLogin==false&&hasPlugs()){
          window.open('https://maimai.cn/platform/login', '_blank');
        }
      }
    }

    let t=0;
    let token = "771B511457B443DF9E9A54226D59CC48"
    function AI(){
      let tokenArr=[]
      getQianchengTokenListAPI().then(res => {
        if (res.success) {
          if(t>=res.data.length){
            t=0;
            return;
          }
          token=res.data[t].userToken;
          tokenArr=res.data;


          let hashKey = 'sfhVda5dsmZf'
          let dataAI = {
            "prompt": "我要招聘 "+data.ruleForm.baseInfo.title+" 工作地点"+(data.ruleForm.baseInfo.cities.length>0?data.ruleForm.baseInfo.cities[0].label:'')+" "+getDicValue(data.educationTypeSelect,data.ruleForm.baseInfo.educationType)+"学历以上 "+data.ruleForm.baseInfo.ageMin+"-"+data.ruleForm.baseInfo.ageMax+"周岁 "+data.ruleForm.baseInfo.experienceMin+"-"+data.ruleForm.baseInfo.experienceMax+"年工作经验 的招聘职位描述和任职要求",
            "coid": "123",
            "divid": "",
            "type": "2",
            "prev_id": "",
            "pageUrl": "https://ehire.51job.com/Revision/job",
            "fromPageUrl": "https://ehire.51job.com/Revision/job",
            "webId": "3",
            "userType": "0",
            "property": "{\"partner\":\"\",\"fromdomain\":\"ehire_web\",\"memType\":\"02\",\"pageUrl\":\"https://ehire.51job.com/Revision/job\",\"frompageUrl\":\"https://ehire.51job.com/Revision/job\",\"ctmid\":\"6801097\",\"hruId\":\"18843499\",\"hraccid\":\"\",\"userType\":\"0\",\"isCustom\":\"\",\"huihuaId\":\"pc-3ddb18c6b2fc7ad78c79a6c18cb1eed2-1703138925716\",\"isInitiative\":\"\",\"terminalType\":0,\"guid\":\"19e9213e22618d17d4475420f7baf174\"}"
          }
          var now = new Date();

          // 获取自Unix纪元以来的毫秒数
          var milliseconds = now.getTime();

          // 转换为10位时间戳（秒）
          var timestamp = Math.round(milliseconds / 1000);
          dataAI.timestamp = timestamp

          // 排序
          let sortData = sort(dataAI)
          // 签名加密
          let sign = new Md5(!0).update(token + sortData + hashKey)['hex']()
          sign = new Md5(!0).update(sign)['hex']()

          dataAI.sign = sign
          axios({
            url: 'https://ehirej.51job.com/jobj/wukong/get_description' ,
            method: 'post',
            headers: {
              "Content-Type": "application/json",
              "Accesstoken": token
            },
            data:JSON.stringify(dataAI)
          }).then((res) => {
            if(res.data.code=="200"){
              var aPos ="";
              var substrs ="";
              if(res.data.data.desc.includes('工作内容】')){
                aPos=res.data.data.desc.split('工作内容】',2);
                substrs = aPos[1].split('【任职要求】', 2);
              }else{
                aPos = res.data.data.desc.split('工作内容：',2);
                substrs = aPos[1].split('职位要求：', 2);
              }
              data.aiJobDescribe=substrs[0] && substrs[0].trim()
              data.isaiJobDescribe = false;
              data.aiJobask=substrs[1] && substrs[1].trim()
              data.isaiJobask = false;
            }
            else{
              for (var i = 0; i < tokenArr.length; i++) {
                token=tokenArr[t].userToken;
                t++
                AI()
                if(res.data.code!="200"&&tokenArr.length==t){
                  AIdoubao()
                }
                break;
              }
            }
          })
        }
      })

    }

    async function AIdoubao() {
      data.aiJobDescribe="";
      data.aiJobask=""
      //AI豆包
      let webSocket = await getWebSocket()
      webSocket.onopen = e => {
        console.log(e)
        webSocketSend(webSocket, buildAiMatchParam("我要招聘 "+data.ruleForm.baseInfo.title+" 工作地点"+(data.ruleForm.baseInfo.cities.length>0?data.ruleForm.baseInfo.cities[0].label:'')+" "+getDicValue(data.educationTypeSelect,data.ruleForm.baseInfo.educationType)+"学历以上 "+data.ruleForm.baseInfo.ageMin+"-"+data.ruleForm.baseInfo.ageMax+"周岁 "+data.ruleForm.baseInfo.experienceMin+"-"+data.ruleForm.baseInfo.experienceMax+"年工作经验 的 职位描述 和 任职要求"))
      }
      function buildAiMatchParam(jobTitle) {
        //console.log(jobTitle)
        return [
          {
            role: 'system',
            content: ''
          },
          {
            role: 'user',
            content: jobTitle
          }
        ]
      }
      async function getWebSocket() {
        return new WebSocket('wss://ats.risfond.com/message-touch/doubao/ws')
      }

      /**
       * 发送消息
       * @param chatId 会话id
       * @param ws websocket对象
       */
      async function webSocketSend(ws, data) {
        ws.send(JSON.stringify(data))
      }
      let contentAll="";
      var aPos ="";
      var substrs ="";
      webSocket.onmessage = e => {
        if(data.aiPoShow==false){
          webSocket.close()
        }
        //console.log(e.data)
        let result = JSON.parse(e.data)
        result = convertBigModuleMessage(result, 2)
        if (result.code === 0) {
          let content = result.content
          if(content!=undefined){
            //content = content.split('\n').join('<br>')

            contentAll+=content;
            contentAll=contentAll.replace("###", "")
            if(contentAll.includes('、职位描述')){
              aPos = contentAll.split('、职位描述',2);
              if(contentAll.includes('、任职要求')){
                substrs = aPos[1].split('、任职要求', 2);
                data.aiJobDescribe=substrs[0] && substrs[0].trim();
              }else{
                data.aiJobDescribe=aPos[1] && aPos[1].trim();
              }
              data.isaiJobDescribe = false;
              if(contentAll.includes('、任职要求')){
                data.aiJobask=substrs[1].trim().split('希望这份',2)[0]
                data.isaiJobask = false;
              }
            }else if(contentAll.includes('职位描述：')){
              aPos = contentAll.split('职位描述：',2);
              if(contentAll.includes('任职要求：')){
                substrs = aPos[1].split('任职要求：', 2);
                data.aiJobDescribe=substrs[0] && substrs[0].trim();
              }else{
                data.aiJobDescribe=aPos[1] && aPos[1].trim();
              }
              data.isaiJobDescribe = false;
              if(contentAll.includes('任职要求：')){
                data.aiJobask=substrs[1].trim().split('希望这份',2)[0]
                data.isaiJobask = false;
              }
            }else if(contentAll.includes('职位描述')&&(contentAll.split('职位描述').length-1)>1){
              aPos = contentAll.split('职位描述',3);
              if(contentAll.includes('任职要求')){
                substrs = aPos[1].split('任职要求', 2);
                data.aiJobDescribe=substrs[0] && substrs[0].trim();
              }else{
                data.aiJobDescribe=aPos[1] && aPos[1].trim();
              }
              data.isaiJobDescribe = false;
              if(contentAll.includes('任职要求')&& contentAll.split('任职要求').length - 1 > 1){
                data.aiJobask=substrs[1].trim().split('希望这份',2)[1]
                data.isaiJobask = false;
              }
            }
          }
        }
      }

      webSocket.onerror = e => {
        console.log(e.data)
        console.log('WebSocket报错，请f12查看详情')
      }
      webSocket.onclose = e => {
        //console.log("contentAll:" + contentAll)
        console.log(e.data)
        console.log('websocket被关闭了')
      }
    }
    function convertBigModuleMessage(result, type) {
      if (type === 1) {
        let convertResult = {
          code: result.header.code,
          content: result.payload.choices.text[0].content,
          message: result.header.message,
        }
        if (result.header.status === 2) {
          convertResult.stopped = true
          convertResult.prompt_tokens = result.payload.usage.text.prompt_tokens
          convertResult.completion_tokens = result.payload.usage.text.completion_tokens
          convertResult.total_tokens = result.payload.usage.text.total_tokens
        }
        return convertResult
      }else if (type === 2) {
        let convertResult = {}
        let usage = result.usage // 此字段不为空时,说明是最后一条消息,可以获取到历史消息的token信息
        convertResult.code = 0
        if (usage) {
          convertResult.stopped = true
          convertResult.prompt_tokens = usage.prompt_tokens?usage.prompt_tokens:0
          convertResult.completion_tokens = usage.completion_tokens?usage.completion_tokens:0
          convertResult.total_tokens = usage.total_tokens?usage.total_tokens:0
          return convertResult
        }
        let delta = result.choices[0].delta
        if (delta) {
          convertResult.content = delta.content
          convertResult.message = delta.content
        }
        let message = result.choices[0].message
        if (message) {
          convertResult.content = message.content
          convertResult.message = message.content
        }
        return convertResult
      }
    }
    return {
      rules,
      ...toRefs(data),
      getDicList,
      salaryMaxRef,
      salaryMinRef,
      experienceMinRef,
      experienceMaxRef,
      ageMinRef,
      ageMaxRef,
      fieldSettingsList,
      addShare,
      deleteShare,
      saveCantactHandle,
      projectDropdownList,
      getParticipantName,
      searchpositionName,
      filterOption,
      addItem,
      moadlShow,
      closeMoadlAi,
      closeMoadlTips,
      loading,
      modalFormRef,
      handleSaveJob,
      goToLink,
      getPositionDetails,
      fileUploadM,
      rightChaClick,
      isMax,
      closeMoadl,
      maxOperation,
      minOperation,
      newData,
      searchCityhandle,
      searchTypehandle,
      companyNameChange,
      restrictInput,
      salarySpceMin,
      salarySpceMax,
      // selectSubCompanyName,
      searchCompanyName,
      validater,
      getDicValue,
      projectDescriptionClick,
      projectRequireClick,
      downloadPlugins,
      visibleChange,
      mouseJobName,
      selectWaiBtn,
      recruitmentChannelChange
    }

    function descriptionStr() {
      let description = "职位描述：\r\n" +data.ruleForm.detailInfo.projectDescription+"\r\n任职要求：\r\n" +data.ruleForm.detailInfo.projectRequire ;
      return description;
    }
    //智联同步职位
    function publishJobZhiLian() {
      (async () => {
        window.open("https://rd6.zhaopin.com/job/publish?from=jobmanage_jobpublish", '_blank');



        /*function getSalary(publishNeedObj) {
          let monthSalary = (publishNeedObj.SalaryFrom * 10000 / 12).toFixed(0)
          if (monthSalary > 100000)
            return {SalaryFrom: 100001, SalaryTo: 110000}
          else if (monthSalary > 70000)
            return {SalaryFrom: 70001, SalaryTo: 100000}
          else if (monthSalary > 50000)
            return {SalaryFrom: 50001, SalaryTo: 70000}
          else if (monthSalary > 35000)
            return {SalaryFrom: 35001, SalaryTo: 50000}
          else if (monthSalary > 25000)
            return {SalaryFrom: 25001, SalaryTo: 35000}
          else if (monthSalary > 15000)
            return {SalaryFrom: 15001, SalaryTo: 25000}
          else if (monthSalary > 10000)
            return {SalaryFrom: 10001, SalaryTo: 15000}
          else if (monthSalary > 8000)
            return {SalaryFrom: 8001, SalaryTo: 10000}
          else if (monthSalary > 6000)
            return {SalaryFrom: 6001, SalaryTo: 8000}
          else if (monthSalary > 4000)
            return {SalaryFrom: 4001, SalaryTo: 6000}
          else if (monthSalary > 2000)
            return {SalaryFrom: 2001, SalaryTo: 4000}
          else if (monthSalary > 1000)
            return {SalaryFrom: 1001, SalaryTo: 2000}
          else
            return {SalaryFrom: 5001, SalaryTo: 9000}
        }*/
        function getEducation(EducationLevelId) {
          let education = [[-1, "0", "不限", "no limit", "buxian"],
            [9, "0", "初中及以下", "junior high", "chuzhongjiyixia"],
            [7, "0", "高中", "Senior High", "gaozhong"],
            [12, "0", "中专/中技", "Secondary Specialized/Skilled Workers Training", "zhongzhuan/zhongji"],
            [5, "0", "大专", "Associate", "dazhuan"],
            [4, "0", "本科", "Bachelor", "benke"],
            [3, "0", "硕士", "Master", "shuoshi"],
            [10, "0", "MBA/EMBA", "MBA/EMBA", "MBA/EMBA"],
            [1, "0", "博士", "Doctor", "boshi"]]
          if (EducationLevelId == 1)
            return education[4][0]
          else if (EducationLevelId == 2)
            return education[5][0]
          else if (EducationLevelId == 3)
            return education[6][0]
          else if (EducationLevelId == 4)
            return education[8][0]
          else if (EducationLevelId == 5)
            return education[7][0]
          else if (EducationLevelId == 6)
            return education[4][0]
          else if (EducationLevelId == 7)
            return education[5][0]
          else if (EducationLevelId == 8)
            return education[6][0]
          else if (EducationLevelId == 9)
            return education[8][0]
          else if (EducationLevelId == 10)
            return education[8][0]
          else if (EducationLevelId == 11)
            return education[2][0]
          else if (EducationLevelId == 12)
            return education[1][0]
          else if (EducationLevelId == 13)
            return education[2][0]
          else if (EducationLevelId == 14)
            return education[2][0]
          else if (EducationLevelId == 15)
            return education[0][0]
          else
            return education[0][0]
        }

        function getWorkAge(YearsExperience) {
          if (YearsExperience == 0)
            return 0;
          else if (YearsExperience >= 1 && YearsExperience <= 3)
            return 103
          else if (YearsExperience > 3 && YearsExperience <= 5)
            return 305
          else if (YearsExperience > 5 && YearsExperience <= 10)
            return 510
          else if (YearsExperience > 10)
            return 1099
          else
            return 0
        }

        /*let getCitys = function () {
          let citys = []
          let zhilianCitys = zhilianchildArr;
          zhilianCitys.forEach((e) => {
            e.children.forEach((t) => {
              citys.push({label: t.label, value: t.value})
            })
          })
          return citys
        }
        let getCoordinates = (addressParam) => {
          return new Promise((resolve) => {
            let coordinates;
            let mapType = "gaode";
            //#region 高德地图获取坐标
            if (mapType == "gaode") {
              gaodeApi(addressParam).then(res => {
                let resStr = res.slice(res.indexOf('{'), res.lastIndexOf('}') + 1)
                let obj = JSON.parse(resStr);
                for (var i = 0; i < obj.tips.length; i++) {
                  if (obj.tips[i] && obj.tips[i].address && obj.tips[i].address.length != 0) {
                    coordinates = obj.tips[i]
                    let locations = coordinates.location.split(',')
                    coordinates.locationJ = locations[0]
                    coordinates.locationW = locations[1]
                    let addressHead = coordinates.district.replace("省", "/").replace("市", "/")
                    coordinates.allAddress = addressHead + "  " + coordinates.address
                    let citys = zhilianchildArr
                    for (var ia = 0; ia < citys.length; ia++) {
                      let item = citys[ia]
                      if (addressHead.indexOf(item.label) > -1) {
                        coordinates.cityId = item.value
                        break
                      }
                    }
                    if (!coordinates.cityId)
                      coordinates = null
                    break
                  }
                }
                resolve(coordinates)
              })
            }
            //#endregion
            //#region 百度地图获取坐标
            if (mapType == "baidu") {
              //this.zhilianPoi = "";
              var map = new BMap.Map("container");
              map.enableScrollWheelZoom(); //启用滚轮放大缩小，默认禁用
              map.enableContinuousZoom(); //启用地图惯性拖拽，默认禁用
              map.addControl(new BMap.NavigationControl()); //添加默认缩放平移控件
              map.addControl(new BMap.OverviewMapControl()); //添加默认缩略地图控件
              let BMAP_ANCHOR_BOTTOM_RIGHT;
              map.addControl(new BMap.OverviewMapControl({
                isOpen: true,
                anchor: BMAP_ANCHOR_BOTTOM_RIGHT
              })); //右下角，打开

              var localSearch = new BMap.LocalSearch(map);
              localSearch.enableAutoViewport(); //允许自动调节窗体大小
              let searchByStationName=function (keyword) {
                map.clearOverlays(); //清空原来的标注
                localSearch.setSearchCompleteCallback((searchResult) => {
                  /!*window.zhilianPoi = searchResult.getPoi(0);*!/
                  localStorage.setItem('zhilianPoi', JSON.stringify(searchResult.getPoi(0)))
                });
                localStorage.setItem('zhilianPoi', '')
                localSearch.search(keyword);
              }

              searchByStationName(addressParam);
              //需要等待回调函数setSearchCompleteCallback执行完以后继续往下执行
            }
            //#endregion
            //百度坐标转高德（传入经度、纬度）
            function bd_decrypt(bd_lng, bd_lat) {
              var X_PI = Math.PI * 3000.0 / 180.0;
              var x = bd_lng - 0.0065;
              var y = bd_lat - 0.006;
              var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
              var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
              var gg_lng = z * Math.cos(theta);
              var gg_lat = z * Math.sin(theta);
              return {lng: gg_lng.toFixed(6), lat: gg_lat.toFixed(6)}
            }

            let count = 0
            let timer = setInterval(() => {
              let zhilianPoi = localStorage.getItem('zhilianPoi')
              if (zhilianPoi) {
                this.zhilianPoi = JSON.parse(zhilianPoi)
                //给coordinates赋值
                let point = bd_decrypt(this.zhilianPoi.point.lng, this.zhilianPoi.point.lat)
                let obj = {}
                obj.locationJ = point.lng
                obj.locationW = point.lat
                obj.address = this.zhilianPoi.address
                let addressHead = this.zhilianPoi.city.replace("市", "")
                obj.allAddress = addressHead + "  " + obj.address
                obj.cityId = 0
                coordinates = obj;
                let citys = getCitys()
                for (var i = 0; i < citys.length; i++) {
                  let item = citys[i]
                  if (addressHead.indexOf(item.label) > -1) {
                    coordinates.cityId = item.value
                    break
                  }
                }
                if (!coordinates.cityId)
                  coordinates = null
                resolve(coordinates)
                clearInterval(timer)
              }
              count++
              if (count > 20)//4秒钟还不返回结果就不等待了
              {
                resolve(coordinates)
                clearInterval(timer)
              }
            }, 200)
          })
        }
        let coordinates = await getCoordinates(data.ruleForm.baseInfo.cities);
        if (!coordinates) {
          //如果查询不到防止报错给一个默认值
          let obj = {}
          obj.locationJ = "116.497821"
          obj.locationW = "39.784753"
          obj.address = "北京市经济技术开发区地盛北街1号院"
          obj.allAddress = "北京  北京市经济技术开发区地盛北街1号院"
          obj.cityId = 530
          obj.district = "北京市大兴区"
          coordinates = obj;
        }*/
        //#region 职位类别
        let getJobTitleCode = function () {
          let jobTitle = { JobTitleCode: '', JobTitleName: '' }
          let jobSubTypeStr = data.ruleForm.baseInfo.professionCategory.length>0?data.ruleForm.baseInfo.professionCategory[0].label:"";
          let list = ZHILIANJOBTITLE;
          //第三级才是真正的职位类别
          for (var i = 0; i < list[0].data[0].data.length; i++) {
            let objI = list[0].data[0].data[i]
            if (objI)
              for (var j = 0; j < objI.length; j++) {
                let objJ = objI.children[j]
                if (objJ)
                  for (var k = 0; k < objJ.children.children.length; k++) {
                    let objK = objJ.children.children[k]
                    if (jobSubTypeStr == objK.label)
                      jobTitle = { JobTitleCode: objK.value, JobTitleName: objK.label }
                    if (jobTitle.JobTitleCode != "")
                      break
                  }
                if (jobTitle.JobTitleCode != "")
                  break
              }
            if (jobTitle.JobTitleCode != "")
              break
          }
          //模糊匹配
          if (jobTitle.JobTitleCode == "") {
            let shortJobSubTypeStr = jobSubTypeStr.length >= 0 ? jobSubTypeStr.substring(0, 2) : ''
            for (var ii = 0; ii < list[0].data[0].data.length; ii++) {
              let objI = list[0].data[0].data[ii]
              if (objI)
                for (var jj = 0; jj < objI.length; jj++) {
                  let objJ = objI.children[jj]
                  if (objJ)
                    for (var kk = 0; kk < objJ.children.children.length; kk++) {
                      let objK = objJ.children.children[kk]
                      if (objK.label.indexOf(shortJobSubTypeStr) > -1)
                        jobTitle = { JobTitleCode: objK.value, JobTitleName: objK.label }
                      if (jobTitle.JobTitleCode != "")
                        break
                    }
                  if (jobTitle.JobTitleCode != "")
                    break
                }
              if (jobTitle.JobTitleCode != "")
                break
            }
          }
          if (jobTitle.JobTitleCode == ""){
            jobTitle = { JobTitleCode: 19000200100000, JobTitleName: '销售顾问' }
          }
          return jobTitle
        }
        let jobTitle = getJobTitleCode()
        //#endregion
        let jobPresentVoStr = {
          "id": 0,
          "jobTitle": data.ruleForm.baseInfo.title,
          "jobSubType": jobTitle.JobTitleCode,
          "fourthLevelJobType": null,
          "employmentType": 2,
          "education": getEducation(data.ruleForm.baseInfo.educationType),
          "workAge": getWorkAge(data.ruleForm.baseInfo.experienceMax),
          "salaryMonths": 12,
          "minSalary": data.ruleForm.baseInfo.salaryMin* 10000,
          "maxSalary": data.ruleForm.baseInfo.salaryMax* 10000,
          "salaryDescription": "",
          "salaryNegotiable": false,
          "jobDescription": descriptionStr(),
          "skillTags": [],
          "benefitTags": [],
          "quantity": parseInt(data.ruleForm.baseInfo.personNum),
          "forceSave": false,
          "isFuture": false,
          "criticalPoint": "",
          "addressList": [{

            "cityId": 785,
            "districtId": 3151,
            "jobAddress": "",
            "releaseCityId": null,
            "businessArea": 0,
            "roomNumber": "",
            "locationType": "NORMAL"

          }],
          "sceneId": "",
          "taskId": "",
          "forwardType": "EMAIL",
          "forwardList": [],
          "forwardStaffList": [{}],
          "addResumeReceiver": false,
          "justSave": false,
          "justCheckSensitiveWords": false,
          "salaryType": "MONTHLY_SALARY",
          "internshipMonths": null,
          "weeklyInternshipDays": null,
          "canBeRegular": false,
          "provideInternshipCertificate": false,
          "canRemoteInternship": false,
          "customIndustryIds": [],
          "skillDetails": [],
          "recruitmentType": "NORMAL",
          "proxiedOrgName": null,
          "proxiedOrgSize": null,
          "proxiedOrgAddress": null,
          "licenceUrls": null,
          "noVerificationReleaseRights": true,
          "displayPhoneNumber": null,
          "displayPhoneBeginTime": null,
          "displayPhoneEndTime": null,
          "allowHolidaysDisplayPhone": null,
          "isExtensionJob": false,
          "extensionJobId": null
        }

        let config = {
          method: 'POST',
          async: false,
          dataType: 'json',
          contentType: "application/json",
          url: 'https://rd6.zhaopin.com/api/job/publish?_=' + Date.now() + '&x-zp-page-request-id=' + generateRandomString(),
          headers: {
            "Content-Type": "application/json"
          },
          data: jobPresentVoStr,
        }
        window.postMessage({
          type: '__RISFOND_PLUGIN_PUBLISH_JOB__',
          subType: 'publishJobZhiLian',
          config: config,
          jobPresentVoStr: JSON.stringify(jobPresentVoStr)
        });
      })();
    }

    //前程无忧同步职位
    function publishJobJob51() {
      (async () => {
        var urltimestamp=Date.now()
        window.open("https://ehire.51job.com/Revision/job?mark=new&rt="+urltimestamp, '_blank');
        function getWorkAge(YearsExperience) {
          if (YearsExperience == 0)
            return 10
          if (YearsExperience > 10)
            return 8
          if (YearsExperience >= 8)
            return 7
          if (YearsExperience >= 5)
            return 6
          if (YearsExperience >= 3)
            return 5
          if (YearsExperience >= 2)
            return 4
          if (YearsExperience >= 1)
            return 3
        }
        function getEducation(EducationLevelId) {
          //<a href="javascript:void(0);" data-value="6" title="本科">本科</a>
          //<a href="javascript:void(0);" data-value="5" title="大专">大专</a>
          //<a href="javascript:void(0);" data-value="7" title="硕士">硕士</a>
          //<a href="javascript:void(0);" data-value="8" title="博士">博士</a>
          //<a href="javascript:void(0);" data-value="4" title="中专">中专</a>
          //<a href="javascript:void(0);" data-value="3" title="中技">中技</a>
          //<a href="javascript:void(0);" data-value="2" title="高中">高中</a>
          //<a href="javascript:void(0);" data-value="1" title="初中及以下">初中及以下</a>
          //<a href="javascript:void(0);" data-value="99" title="无学历要求">无学历要求</a>
          if (EducationLevelId == 15)
            return 99
          else if (EducationLevelId == 1)
            return 5
          else if (EducationLevelId == 2)
            return 6
          else if (EducationLevelId == 3)
            return 7
          else if (EducationLevelId == 4)
            return 8
          else if (EducationLevelId == 5)
            return 7
          else if (EducationLevelId == 6)
            return 5
          else if (EducationLevelId == 7)
            return 6
          else if (EducationLevelId == 8)
            return 7
          else if (EducationLevelId == 9)
            return 8
          else if (EducationLevelId == 10)
            return 8
          else if (EducationLevelId == 11)
            return 2
          else if (EducationLevelId == 12)
            return 1
          else if (EducationLevelId == 13)
            return 2
          else if (EducationLevelId == 14)
            return 2
          else
            return 99
        }
        //#region 职位类别
        let getJobTitleCode = function () {
          let jobTitle = { JobTitleCode: '', JobTitleName: '' }
          let jobSubTypeStr = data.ruleForm.baseInfo.professionCategory.length>0?data.ruleForm.baseInfo.professionCategory[0].label:""
          let list = JOB51JOBTITLE
          for (var i = 0; i < list.length; i++) {
            let obj = list[i]
            if (jobSubTypeStr == obj.value)
              jobTitle = { JobTitleCode: obj.code, JobTitleName: obj.value }
            if (jobTitle.JobTitleCode != "")
              break
          }
          //模糊匹配
          if (jobTitle.JobTitleCode == "") {
            let shortJobSubTypeStr = jobSubTypeStr.length >= 0 ? jobSubTypeStr.substring(0, 2) : ''
            for (var ii = 0; ii < list.length; ii++) {
              let obj = list[ii]
              if (obj.value.indexOf(shortJobSubTypeStr) > -1)
                jobTitle = { JobTitleCode: obj.code, JobTitleName: obj.value }
              if (jobTitle.JobTitleCode != "")
                break
            }
          }
          if (jobTitle.JobTitleCode == ""){
            jobTitle = { JobTitleCode: '0142', JobTitleName: '其他' }
          }
          return jobTitle
        }
        //#endregion
        let jobTitle = getJobTitleCode()
        let jobPresentVoStr =  {
          "mark": 0,
          "publishnumberType": "",
          "isSyncServiceAD": "",
          "rememberme": "",
          "jobId": "",
          "isTmp": "",
          "jobType": 0,
          "coid": "",//接口获取
          "divid": "",
          "jobName": data.ruleForm.baseInfo.title,
          "term": "0",
          "jobAreas": jOBCitys.join("|"),
          "jobWelfare": "五险一金",
          "jobKeywords": "",
          "jobStatus": "",
          "workareaId": "",//接口获取
          "workAddress": "",//接口获取
          "workArea": "",//接口获取
          "lon": "",
          "lat": "",
          "funcType": jobTitle.JobTitleCode,
          "doubleChannel": "000",
          "salaryType": "4",
          "salaryFrom": data.ruleForm.baseInfo.salaryMin* 10000,
          "salaryTo": data.ruleForm.baseInfo.salaryMax* 10000,
          "salaryYearFrom": "",
          "salaryYearTo": "",
          "daySalary": "",
          "salayFactor": 12,
          "isHidJobSalary": 0,
          "jobInfo": descriptionStr(),
          "workYear": getWorkAge(data.ruleForm.baseInfo.experienceMax),
          "degreeFrom": getEducation(data.ruleForm.baseInfo.educationType),
          "ageFrom": data.ruleForm.baseInfo.ageMin,
          "ageTo": data.ruleForm.baseInfo.ageMax,
          "fl1": "",
          "flevel1": "",
          "fl2": "",
          "flevel2": "",
          "customFL1": "",
          "customFL2": "",
          "isShare": "0",
          "is51Job": "1",
          "isOther": "1",
          "isUrgency": "",
          "isCustom": "",
          "jobOrder": "",
          "emailType": 0,
          "emailAddress": "",
          "poscode": "",
          "workhour": "",
          "isBasesalary": "",
          "entrustconfirmed": "",
          "agentCompanyname": "",
          "ydDeductAvaid": "",
          "relation": "0",
          "levelType": 0,
          "internMonths": "",
          "unionOrderType": "",
          "unionOrderCost": 0,
          "internWeekDays": "",
          "statistic_map": "{\"from_module\":\"新增职位页\"}",
          "pskillStr": "",
          "pskillCode": "",
          "payDay": "",
          "basicSalary": "",
          "settleDate": "",
          "comMissionStr": "",
          "comMissionCode": "",
          "workdaysOfWeek": "",
          "worktimeOfDay": "",
          "jobWelfCode": "",
          "contactAllowed": "0",
          "contactDay": "",
          "contactTime": "",
          "workYearUp": data.ruleForm.baseInfo.experienceMax,
          "workYearDown": data.ruleForm.baseInfo.experienceMin,
          "contactInfo": "",
          "jobWelfCodeDetail": [],
          "majors": "",
          "newIndustry": "",
          "newIndustryPreference": "",
          "newIndustryPreferenceStr": "",
          "salaryFactor": 12,
          "workMonths": "",
          "workDays": "",
          "onlyCalculateDedcut": "0",
          "ifJobnameCustomized": "0",
          "funtypeMode": "1",
          "keywordsCustomized": "",
          "ifUseExample": "0",
          "ifworkAreaAdded": "0",
          "experiencePrevious": "",
          "educationPrevious": "",
          "agePrevious": "",
          "welfareCustomized": "",
          "ifMoreChannel": "0",
          "exampleIconIfShow": "0",
          "examplePopIfShow": "0",
          "showContentType": "",
          "experienceIfShow": "1",
          "experienceBefore": "",
          "experienceSuggestValue": "",
          "educationIfShow": "0",
          "educationBefore": "",
          "educationSuggestValue": "",
          "ageIfShow": "0",
          "ageBShow":"more"
        }

        var now = new Date();

// 获取自Unix纪元以来的毫秒数
        var milliseconds = now.getTime();

// 转换为10位时间戳（秒）
        var timestamp = Math.round(milliseconds / 1000);
        jobPresentVoStr.timestamp = timestamp
        let config = {
          method: 'POST',
          async: false,
          url: 'https://open.51job.com/newapi/open/jobs/publish_societyjob.ashx',
          headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': 'origin url of your site',
            "Access-Control-Allow-Methods":"GET, POST,PUT"
          },
          xhrFields: {
            withCredentials: true //允许跨域带Cookie
          },
          data: jobPresentVoStr
        }
        window.postMessage({
          type: '__RISFOND_PLUGIN_PUBLISH_JOB__',
          subType: 'publishJobJob51',
          config: config,
          jobPresentVoStr: jobPresentVoStr,
          urltimestamp:urltimestamp
        });
      })();
    }
    //boss同步职位
    function getNowDate() {
      var myDate = new Date;
      var year = myDate.getFullYear(); //获取当前年
      var mon = myDate.getMonth() + 1; //获取当前月
      if (mon < 10) {
        mon = "0" + mon;
      }
      var date = myDate.getDate(); //获取当前日
      if (date < 10) {
        date = "0" + date;
      }
      var hours = myDate.getHours(); //获取当前小时
      if (hours < 10) {
        hours = "0" + hours;
      }
      var minutes = myDate.getMinutes(); //获取当前分钟
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      var seconds = myDate.getSeconds(); //获取当前秒
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      var now = year + "-" + mon + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
      return now;
    }
    function getTimestamp() {
      return new Date(getNowDate()).getTime();
    }
    function pe() {
      let e = (new Date).getTime();
      return window.performance && "function" === typeof window.performance.now && (e += performance.now()),
          "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (function (t) {
                let i = (e + 16 * Math.random()) % 16 | 0;
                return e = Math.floor(e / 16),
                    ("x" === t ? i : 3 & i | 8).toString(16)
              }
          ))
    }
    function publishJobZhipin() {
      (async () => {
        window.open("https://www.zhipin.com/web/chat/job/edit?encryptId=0", '_blank');
  //#region 工作年限
        //let workYear = {
        //  101: "经验不限",
        //  103: "1年以内",
        //  104: "1-3年",
        //  105: "3-5年",
        //  106: "5-10年",
        //  107: "10年以上",
        //  109: "在校/应届"
        //}
        function getWorkYear (YearsExperience) {
          if (YearsExperience == 0)
            return 101
          if (YearsExperience > 10)
            return 107
          if (YearsExperience >= 5)
            return 106
          if (YearsExperience >= 3)
            return 105
          if (YearsExperience >= 1)
            return 104
        }
        //#region 学历
        function getEducation(EducationLevelId) {
          /*let education = {
            201: "学历不限",
            209: "初中及以下",
            208: "中专/中技",
            206: "高中",
            202: "大专",
            203: "本科",
            204: "硕士",
            205: "博士"
          }*/
          if (EducationLevelId == 15)
            return 201
          else if (EducationLevelId == 1)
            return 202
          else if (EducationLevelId == 2)
            return 203
          else if (EducationLevelId == 3)
            return 204
          else if (EducationLevelId == 4)
            return 205
          else if (EducationLevelId == 5)
            return 204
          else if (EducationLevelId == 6)
            return 202
          else if (EducationLevelId == 7)
            return 203
          else if (EducationLevelId == 8)
            return 204
          else if (EducationLevelId == 9)
            return 205
          else if (EducationLevelId == 10)
            return 205
          else if (EducationLevelId == 11)
            return 206
          else if (EducationLevelId == 12)
            return 209
          else if (EducationLevelId == 13)
            return 206
          else if (EducationLevelId == 14)
            return 206
          else
            return 201
        }
        //#endregion
        //#region 职位类别
        let getJobTitleCode = function () {
          let jobTitle = { JobTitleCode: '', JobTitleName: '' }
          let jobSubTypeStr = data.ruleForm.baseInfo.professionCategory.length>0?data.ruleForm.baseInfo.professionCategory[0].label:"";
          let list = ZHIPINJOBTITLE;
          //第三级才是真正的职位类别
          for (var i = 0; i < list.length; i++) {
            let objI = list[i]
            if (objI.subLevelModelList)
              for (var j = 0; j < objI.subLevelModelList.length; j++) {
                let objJ = objI.subLevelModelList[j]
                if (objJ.subLevelModelList)
                  for (var k = 0; k < objJ.subLevelModelList.length; k++) {
                    let objK = objJ.subLevelModelList[k]
                    if (jobSubTypeStr == objK.name)
                      jobTitle = { JobTitleCode: objK.code, JobTitleName: objK.name }
                    if (jobTitle.JobTitleCode != "")
                      break
                  }
                if (jobTitle.JobTitleCode != "")
                  break
              }
            if (jobTitle.JobTitleCode != "")
              break
          }
          //模糊匹配
          if (jobTitle.JobTitleCode == "") {
            let shortJobSubTypeStr = jobSubTypeStr.length >= 0 ? jobSubTypeStr.substring(0, 2) : ''
            for (var ii = 0; ii < list.length; ii++) {
              let objI = list[ii]
              if (objI.subLevelModelList)
                for (var jj = 0; jj < objI.subLevelModelList.length; jj++) {
                  let objJ = objI.subLevelModelList[jj]
                  if (objJ.subLevelModelList)
                    for (var kk = 0; kk < objJ.subLevelModelList.length; kk++) {
                      let objK = objJ.subLevelModelList[kk]
                      if (objK.name.indexOf(shortJobSubTypeStr) > -1)
                        jobTitle = { JobTitleCode: objK.code, JobTitleName: objK.name }
                      if (jobTitle.JobTitleCode != "")
                        break
                    }
                  if (jobTitle.JobTitleCode != "")
                    break
                }
              if (jobTitle.JobTitleCode != "")
                break
            }
          }
          if (jobTitle.JobTitleCode == ""){
            jobTitle = { JobTitleCode: '200101', JobTitleName: '其他职位' }
          }
          return jobTitle
        }
        let jobTitle = getJobTitleCode()
        //#endregion
        let jobPresentVoStr =  {
          "comId": "",
          "brandId": 0,
          "positionName": data.ruleForm.baseInfo.title,
          "positionCategory": jobTitle.JobTitleName,
          "position": jobTitle.JobTitleCode,
          "experience": getWorkYear(data.ruleForm.baseInfo.experienceMax),
          "degree": getEducation(data.ruleForm.baseInfo.educationType),
          "skill[]": data.ruleForm.baseInfo.title,
          "jstr": "",
          "jobType": 0,
          "lowSalary": data.ruleForm.baseInfo.salaryMin,
          "highSalary": data.ruleForm.baseInfo.salaryMax,
          "salaryMonth": 12,
          "leastMonth": 0,
          "daysPerWeek": 0,
          "performance":"",
          "description": descriptionStr(),
          "department":"",
          "reportObject":"",
          "graduateYear": 0,
          "lowGraduateYear": 0,
          "recruitEndTime":"",
          "anonymous": 0,
          "requireIndustries":"",
          "requireIndustriesDesc":"",
          "trainingPlan":"",
          "workday": 0,
          "salaryType": 0,
          "acType": 0,
          "periodType": 2,
          "period":"",
          "deadline":"",
          "basicSalary": 0,
          "highBasicSalary": 0,
          "salaryDay": 0,
          "socialInsuranceType": 0,
          "other":"",
          "createType": 0,
          "probation":"",
          "socialInsuranceTypeName":"",
          "preferenceJsonStr": "",
          //"preferenceJsonStr": "",
          "knowBlueIntermediaryTip": 0,
          "proxyType": 1,
          "performanceExplain":"",
          "relationIdJson": "",
          //"relationIdJson": "",
          "phoneAssistantStatus": 0,
          "projectIdStr":"",
          "proxySelfFlag": true,
          "mustAnonymousType": 0,
          "workType": 0,
          "workArea":"",
          "highAge": 0,
          "lowAge": 0,
          "officialLiveSwitch": 0,
          "companyNotConformanceConfirm": 0,
          "geekRequirement":"",
          "preferredPubFrom":"",
          "acceptOverseas": 0,
          "overseasArea":"",
          "overseasLanguage":"",
          "overseasDuration":"",
          "partTimeSwitchStatus": 0,
          "partTimeLowSalary": 0,
          "partTimeHighSalary": 0,
          "partTimeSalaryType": 0,
          "partTimeJobJson":"",
          "needProxyCom": true,
          "hunterPublish": 0,
          "publishScene": 0,
          "spreadCity":"",
          "editFlag": "510e8479c35d38ecnGYghI_EREBawIW3UvqeTeGjg-fCa0k-guRBkL7rJYG8Ww8DKueVmeRjIcfttEtsS4EYwG9ht5U~"
        }
        let config = {
          method: 'POST',
          url: 'https://www.zhipin.com/wapi/zpjob/job/save?_='+getTimestamp(),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "x-fscp-version": "1.1",
            "x-requested-with": "XMLHttpRequest",
            "X-Client-Type": "web",
            "x-fscp-fe-version": "",
            "x-fscp-std-info": '{"client_id": "40095"}',
            "X-Xsrf-Token": "7fFc30RJShqzkRB2dVX-XQ",
            "x-fscp-trace-id": pe()
          },
          xhrFields: {
            withCredentials: true //允许跨域带Cookie
          },
          contentType: "application/x-www-form-urlencoded",
          data: jobPresentVoStr
        }
        window.postMessage({
          type: '__RISFOND_PLUGIN_PUBLISH_JOB__',
          subType: 'publishJobZhipin',
          config: config,
          jobPresentVoStr: jobPresentVoStr
        });
      })();
    }

    /*function AI(){
      let token = "34F4831131C04113B6230608F4670E74"
      let hashKey = 'sfhVda5dsmZf'
      let dataAI = {
        "prompt": "我要招聘 "+data.ruleForm.baseInfo.title+" 工作地点"+(data.ruleForm.baseInfo.cities.length>0?data.ruleForm.baseInfo.cities[0].label:'')+" "+getDicValue(data.educationTypeSelect,data.ruleForm.baseInfo.educationType)+"学历以上 "+data.ruleForm.baseInfo.ageMin+"-"+data.ruleForm.baseInfo.ageMax+"周岁 "+data.ruleForm.baseInfo.experienceMin+"-"+data.ruleForm.baseInfo.experienceMax+"年工作经验 的招聘职位描述和任职要求",
        "coid": "123",
        "divid": "",
        "type": "1",
        "prev_id": "",
        "pageUrl": "https://ehire.51job.com/Revision/job",
        "fromPageUrl": "https://ehire.51job.com/Revision/job",
        "webId": "3",
        "userType": "0",
        "property": "{\"partner\":\"\",\"fromdomain\":\"ehire_web\",\"memType\":\"02\",\"pageUrl\":\"https://ehire.51job.com/Revision/job\",\"frompageUrl\":\"https://ehire.51job.com/Revision/job\",\"ctmid\":\"6801097\",\"hruId\":\"18843499\",\"hraccid\":\"\",\"userType\":\"0\",\"isCustom\":\"\",\"huihuaId\":\"pc-3ddb18c6b2fc7ad78c79a6c18cb1eed2-1703138925716\",\"isInitiative\":\"\",\"terminalType\":0,\"guid\":\"19e9213e22618d17d4475420f7baf174\"}"
      }
      var now = new Date();

// 获取自Unix纪元以来的毫秒数
      var milliseconds = now.getTime();

// 转换为10位时间戳（秒）
      var timestamp = Math.round(milliseconds / 1000);
      dataAI.timestamp = timestamp

// 排序
      let sortData = sort(dataAI)
// 签名加密
      let sign = new Md5(!0).update(token + sortData + hashKey)['hex']()
      sign = new Md5(!0).update(sign)['hex']()

      dataAI.sign = sign
      axios({
        url: 'https://ehirej.51job.com/jobj/wukong/get_description' ,
        method: 'post',
        headers: {
          "Content-Type": "application/json",
          "Accesstoken": token
        },
        data:JSON.stringify(dataAI)
      }).then((res) => {
        if(res.data.code=="200"){
          var aPos = res.data.data.desc.split('工作内容：',2);
          var substrs = aPos[1].split('职位要求：', 2);
          data.aiJobDescribe=substrs[0].trim()
          data.aiJobask=substrs[1].trim()
        }
      })
    }*/

    function publishJobMaiMai() {
      (async () => {
        window.open("https://maimai.cn/ent/talents/recruit/positions/add", '_blank');
        //#region 工作年限

        function getWorkYear (YearsExperience) {
          if (YearsExperience == 0)
            return 5
          if (YearsExperience > 10)
            return 4
          if (YearsExperience >= 5)
            return 3
          if (YearsExperience >= 3)
            return 2
          if (YearsExperience >= 1)
            return 1
        }
        //#region 学历
        function getEducation(EducationLevelId) {
          //b = [{ value: 0, label: "学历不限" },
          //  { value: 1, label: "本科及以上" },
          //  { value: 2, label: "硕士及以上" },
          //  { value: 3, label: "博士" },
          //  { value: 4, label: "专科及以上" }]
          if (EducationLevelId == 15)
            return 0
          else if (EducationLevelId == 1)
            return 4
          else if (EducationLevelId == 2)
            return 1
          else if (EducationLevelId == 3)
            return 2
          else if (EducationLevelId == 4)
            return 3
          else if (EducationLevelId == 5)
            return 2
          else if (EducationLevelId == 6)
            return 4
          else if (EducationLevelId == 7)
            return 1
          else if (EducationLevelId == 8)
            return 2
          else if (EducationLevelId == 9)
            return 3
          else if (EducationLevelId == 10)
            return 3
          else if (EducationLevelId == 11)
            return 0
          else if (EducationLevelId == 12)
            return 0
          else if (EducationLevelId == 13)
            return 0
          else if (EducationLevelId == 14)
            return 0
          else
            return 0
        }
        //#endregion
        function roundToNearestThousand(num) {
          // 先四舍五入到最接近的整数
          var rounded = Math.round(num);

          // 然后向上取整到整千
          var result = Math.ceil(rounded / 1000) * 1000;

          return result;
        }
        //#endregion
        let jobPresentVoStr =  {
            "position": data.ruleForm.baseInfo.title,
            "company": "",
            "major_new": "01",
            "profession_new": "0101",
            "description": descriptionStr(),
            "is_hunter": false,
            "stags": "",
            "province": "",
            "city": "",
            "email": "",
            "custom_text": "",
            "address": "",
            "is_public": 1,
            "is_regular": 0,
            "major_keywords": "",
            "profession": "",
            "major": "",
            "profession_path": "",
            "crtime": "",
            "profession_name": "",
            "cid": 11134,
            "has_golden_job": false,
            "golden_job_unused": 0,
            "salary_min": roundToNearestThousand((data.ruleForm.baseInfo.salaryMin* 10000 / 12).toFixed(0)),
            "salary_max": roundToNearestThousand((data.ruleForm.baseInfo.salaryMax* 10000 / 12).toFixed(0)),
            "position_type": "",
            "position_keywords": [

            ],
            "work_time": getWorkYear(data.ruleForm.baseInfo.experienceMax),
            "degree": getEducation(data.ruleForm.baseInfo.educationType),
            "salary_share": 12,
            "position_key": ""
        }
        let config =
            {
              method: 'POST',
              async: false,
              dataType: 'json',
              contentType: "application/json",
              url: 'https://maimai.cn/sdk/jobs/publish_job/add_job',
              headers: {
                "Content-Type": "application/json"
              },
              appid: 2,
              infos: JSON.stringify(jobPresentVoStr),
            }

        window.postMessage({
          type: '__RISFOND_PLUGIN_PUBLISH_JOB__',
          subType: 'publishJobMaimai',
          config: config
        });
      })();
    }
  },
})

//同步招聘平台职位
let JobCityInput = [];
let JobCityInput51 = [];

//获取前程无忧登录信息
function getUserInfo51Job() {
  let jobPresentVoStr = {
    "fromPageUrl": "",
    "pageUrl": "https://ehire.51job.com/Revision/talent/search",
    //"property":"{\"partner\":\"\",\"fromdomain\":\"ehire_web\",\"memType\":\"02\",\"pageUrl\":\"https://ehire.51job.com/Revision/talent/search\",\"frompageUrl\":\"https://ehire.51job.com/\",\"ctmid\":\"6801097\",\"hruId\":\"18843499\",\"hraccid\":\"\",\"userType\":\"0\",\"isCustom\":\"\",\"huihuaId\":\"pc-6808ad31064aaea116df11fb18452991-1706171943568\",\"isInitiative\":\"\",\"terminalType\":0,\"guid\":\"61bfa600e39eb62b8bf6a80cf4ced191\"}",
    "userType": "0",
    "webId": "3"
  }
  var now = new Date();
// 获取自Unix纪元以来的毫秒数
  var milliseconds = now.getTime();
// 转换为10位时间戳（秒）
  var timestamp = Math.round(milliseconds / 1000);
  jobPresentVoStr.timestamp = timestamp
  let config = {
    method: 'POST',
    url: 'https://ehirej.51job.com/user/personal/get_user_info',
    data: jobPresentVoStr,
    headers: {
      "Content-Type": "application/json"
    },
    xhrFields: {
      withCredentials: true //允许跨域带Cookie
    }
  }
  window.postMessage({
    type: '__RISFOND_PLUGIN_REQUEST__',
    subType: '51JOBUserInfo',
    config: config
  });

}

//获取boss登录信息
function getUserInfoZhiPin() {
  let config = {
    method: 'get',
    url: 'https://www.zhipin.com/wapi/zpboss/h5/user/info',

    xhrFields: {
      withCredentials: true //允许跨域带Cookie
    }
  }
  window.postMessage({
    type: '__RISFOND_PLUGIN_REQUEST__',
    subType: 'zhipinUserInfo',
    config: config
  });
}

//获取智联用户信息
function getUserInfoZhiLian() {
  let config = {
    method: 'get',
    url: 'https://rd6.zhaopin.com/api/session',
    headers: {
      "Content-Type": "application/json"
    },
    xhrFields: {
      withCredentials: true //允许跨域带Cookie
    }
  }
  document.getElementById("PlugsVersionATS").value=0
  window.postMessage({
    type: '__RISFOND_PLUGIN_REQUEST__',
    subType: 'zhilianUserInfo',
    config: config
  });

}
//获取脉脉用户信息
function getUserInfoMaiMai() {
  let config = {
    method: 'get',
    async: false,
    url: 'https://maimai.cn/bizjobs/company/manage/get_admin_current?channel=www&version=1.0.0',
    headers: {
      "Content-Type": "application/json"
    },
    xhrFields: {
      withCredentials: true //允许跨域带Cookie
    }
  }
  window.postMessage({
    type: '__RISFOND_PLUGIN_REQUEST__',
    subType: 'maimaiUserInfo',
    config: config
  });
}
//获取智联城市信息
function getCitysZhiLian() {
  let jobPresentVoStr = {
    "maxLevel": "city",
    "canSelectCity": 1,
    "canSelectProvince": 1,
    "canSelectUnlimit": 0,
    "canSelectWholeCountry": 0,
    "canSelectForeignCountry": 1,
    "canSelectForeign": 1,
    "useCustomWholeLabel": 1
  }
  let config = {
    method: 'get',
    url: 'https://fe.zhaopin.com/data-normalization/api/region-relation',
    data: jobPresentVoStr,
    headers: {
      "Content-Type": "application/json"
    },
    xhrFields: {
      withCredentials: true //允许跨域带Cookie
    }
  }
  window.postMessage({
    type: '__RISFOND_PLUGIN_REQUEST__',
    subType: 'zhilianCitys',
    config: config
  });

}

//获取boss城市信息
function getCitysZhiPin() {
  let config = {
    method: 'get',
    url: 'https://www.zhipin.com/wapi/zpCommon/data/city.json',

    xhrFields: {
      withCredentials: true //允许跨域带Cookie
    }
  }
  window.postMessage({
    type: '__RISFOND_PLUGIN_REQUEST__',
    subType: 'zhipinCitys',
    config: config
  });

}

//获取前程无忧城市信息
function getCitys51Job() {
  let config = {
    method: 'get',
    url: 'https://js.51jobcdn.com/ehire2021/common/dd/jobarea.js',
    headers: {
      "Content-Type": "application/json"
    },
    xhrFields: {
      withCredentials: true //允许跨域带Cookie
    }
  }
  window.postMessage({
    type: '__RISFOND_PLUGIN_REQUEST__',
    subType: '51JOBCitys',
    config: config
  });

}

//获取boss职位信息
function getJobZhiPin() {
  let config = {
    method: 'get',
    url: 'https://www.zhipin.com/wapi/zpjob/job/recJobList',

    xhrFields: {
      withCredentials: true //允许跨域带Cookie
    }
  }
  window.postMessage({
    type: '__RISFOND_PLUGIN_REQUEST__',
    subType: 'zhipinJob',
    config: config
  });

}

//获取前程无忧职位信息
function getJob51Job() {
  let jobPresentVoStr = {
    fromPageUrl: "https://ehire.51job.com/Revision/talent/search",
    isChatList: "1",
    jobName: "",
    pageIndex: "1",
    pageSize: "50",
    returnCoName: "1",
    pageUrl: "https://ehire.51job.com/Revision/talent/search",
    property: "{\"partner\":\"\",\"fromdomain\":\"ehire_web\",\"memType\":\"02\",\"pageUrl\":\"https://ehire.51job.com/Revision/talent/search\",\"frompageUrl\":\"\",\"ctmid\":\"6801097\",\"hruId\":\"18843499\",\"hraccid\":\"\",\"userType\":\"0\",\"isCustom\":\"\",\"huihuaId\":\"pc-747f404df4064f77084f17d794cd82d0-1689129226956\",\"isInitiative\":\"\",\"terminalType\":0,\"guid\":\"e8ae30d8565b7a35e9559a0ef6f6a2ab\"}",
    type: "0",
    webId: "3",
    userType: "0"
  }
  jobPresentVoStr.timestamp = Date.now()
  let config = {
    method: 'POST',
    url: 'https://ehirej.51job.com/job/get_talent_joblist',
    data: jobPresentVoStr,
    headers: {
      "Content-Type": "application/json"
    },
    xhrFields: {
      withCredentials: true //允许跨域带Cookie
    }
  }
  window.postMessage({
    type: '__RISFOND_PLUGIN_REQUEST__',
    subType: '51JOBJob',
    config: config
  });

}

//获取智联职位信息
function getJobZhiLian() {
  let jobPresentVoStr = {
    states: ["RELEASED"]
  }
  let config = {
    method: 'POST',
    url: 'https://rd6.zhaopin.com/api/job/list?_=' + Date.now() + '&x-zp-page-request-id='+generateRandomString()+"&x-zp-client-id=06b74506-6e33-455c-b542-095ac1a5fc6f",
    data: JSON.stringify(jobPresentVoStr),
    headers: {
      "Content-Type": "application/json"
    },
    xhrFields: {
      withCredentials: true //允许跨域带Cookie
    },
  }
  window.postMessage({
    type: '__RISFOND_PLUGIN_REQUEST__',
    subType: 'zhilianJob',
    config: config
  });
}
//获取boss职位信息
function getJobMaiMai() {
  let config = {
    method: 'get',
    url: 'https://maimai.cn/api/ent/job/list?channel=www&page=0&version=1.0.0',

    xhrFields: {
      withCredentials: true //允许跨域带Cookie
    }
  }
  window.postMessage({
    type: '__RISFOND_PLUGIN_REQUEST__',
    subType: 'maimaiJob',
    config: config
  });

}
//获取智联地址信息
/*function getAddressListZhiLian() {
  let jobPresentVoStr = {

  }
  let config = {
    method: 'POST',
    url: 'https://rd6.zhaopin.com/api/job/publish/getAddressList?_=' + Date.now() + '&x-zp-page-request-id='+generateRandomString()+'&x-zp-client-id=06b74506-6e33-455c-b542-095ac1a5fc6f',
    data: JSON.stringify(jobPresentVoStr),
    async: false,
    headers: {
      "Content-Type": "application/json"
    }
  }
  window.postMessage({
    type: '__RISFOND_PLUGIN_REQUEST__',
    subType: 'zhilianJobAddressList',
    config: config
  });

}*/
function generateRandomString() {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const parts = [];
  const partLengths = [32, 13, 6]; // Length of each part

  for (let i = 0; i < partLengths.length; i++) {
    let part = '';
    for (let j = 0; j < partLengths[i]; j++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      part += characters.charAt(randomIndex);
    }
    parts.push(part);
  }

  return parts.join('-');
}




var ERROR = "input is invalid type"
    , WINDOW = "object" === typeof window
    , root = WINDOW ? window : {};
root.JS_MD5_NO_WINDOW && (WINDOW = !1);
var WEB_WORKER = !WINDOW && "object" === typeof self
    , NODE_JS = !root.JS_MD5_NO_NODE_JS && "object" === typeof process && process.versions && process.versions.node;
NODE_JS ? root = global : WEB_WORKER && (root = self);
var ARRAY_BUFFER = !root.JS_MD5_NO_ARRAY_BUFFER && "undefined" !== typeof ArrayBuffer, HEX_CHARS = "0123456789abcdef".split(""), EXTRA = [128, 32768, 8388608, -2147483648], SHIFT = [0, 8, 16, 24], OUTPUT_TYPES = ["hex", "array", "digest", "buffer", "arrayBuffer", "base64"], BASE64_ENCODE_CHAR = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/".split(""), blocks = [], buffer8;
if (ARRAY_BUFFER) {
  var buffer = new ArrayBuffer(68);
  buffer8 = new Uint8Array(buffer),
      blocks = new Uint32Array(buffer)
}
!root.JS_MD5_NO_NODE_JS && Array.isArray || (Array.isArray = function(e) {
      return "[object Array]" === Object.prototype.toString.call(e)
    }
),
!ARRAY_BUFFER || !root.JS_MD5_NO_ARRAY_BUFFER_IS_VIEW && ArrayBuffer.isView || (ArrayBuffer.isView = function(e) {
      return "object" === typeof e && e.buffer && e.buffer.constructor === ArrayBuffer
    }
);
var createOutputMethod = function(e) {
  return function(t) {
    createMethod
    return new Md5(!0).update(t)[e]()
  }
}
    , createMethod = function() {
  var e = createOutputMethod("hex");
  NODE_JS && (e = nodeWrap(e)),
      e.create = function() {
        return new Md5
      }
      ,
      e.update = function(t) {
        return e.create().update(t)
      }
  ;
  for (var t = 0; t < OUTPUT_TYPES.length; ++t) {
    var i = OUTPUT_TYPES[t];
    e[i] = createOutputMethod(i)
  }
  return e
}
    , nodeWrap = function(method) {
  var crypto = eval("require('crypto')")
      , Buffer = eval("require('buffer').Buffer")
      , nodeMethod = function(e) {
    if ("string" === typeof e)
      return crypto.createHash("md5").update(e, "utf8").digest("hex");
    if (null === e || void 0 === e)
      throw ERROR;
    return e.constructor === ArrayBuffer && (e = new Uint8Array(e)),
        Array.isArray(e) || ArrayBuffer.isView(e) || e.constructor === Buffer ? crypto.createHash("md5").update(new Buffer(e)).digest("hex") : method(e)
  };
  return nodeMethod
};
function Md5(e) {
  if (e)
    blocks[0] = blocks[16] = blocks[1] = blocks[2] = blocks[3] = blocks[4] = blocks[5] = blocks[6] = blocks[7] = blocks[8] = blocks[9] = blocks[10] = blocks[11] = blocks[12] = blocks[13] = blocks[14] = blocks[15] = 0,
        this.blocks = blocks,
        this.buffer8 = buffer8;
  else if (ARRAY_BUFFER) {
    var t = new ArrayBuffer(68);
    this.buffer8 = new Uint8Array(t),
        this.blocks = new Uint32Array(t)
  } else
    this.blocks = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  this.h0 = this.h1 = this.h2 = this.h3 = this.start = this.bytes = this.hBytes = 0,
      this.finalized = this.hashed = !1,
      this.first = !0
}
Md5.prototype.update = function(e) {
  if (!this.finalized) {
    var t, i = typeof e;
    if ("string" !== i) {
      if ("object" !== i)
        throw ERROR;
      if (null === e)
        throw ERROR;
      if (ARRAY_BUFFER && e.constructor === ArrayBuffer)
        e = new Uint8Array(e);
      else if (!Array.isArray(e) && (!ARRAY_BUFFER || !ArrayBuffer.isView(e)))
        throw ERROR;
      t = !0
    }
    var n, r, a = 0, o = e.length, s = this.blocks, c = this.buffer8;
    while (a < o) {
      if (this.hashed && (this.hashed = !1,
          s[0] = s[16],
          s[16] = s[1] = s[2] = s[3] = s[4] = s[5] = s[6] = s[7] = s[8] = s[9] = s[10] = s[11] = s[12] = s[13] = s[14] = s[15] = 0),
          t)
        if (ARRAY_BUFFER)
          for (r = this.start; a < o && r < 64; ++a)
            c[r++] = e[a];
        else
          for (r = this.start; a < o && r < 64; ++a)
            s[r >> 2] |= e[a] << SHIFT[3 & r++];
      else if (ARRAY_BUFFER)
        for (r = this.start; a < o && r < 64; ++a)
          n = e.charCodeAt(a),
              n < 128 ? c[r++] = n : n < 2048 ? (c[r++] = 192 | n >> 6,
                  c[r++] = 128 | 63 & n) : n < 55296 || n >= 57344 ? (c[r++] = 224 | n >> 12,
                  c[r++] = 128 | n >> 6 & 63,
                  c[r++] = 128 | 63 & n) : (n = 65536 + ((1023 & n) << 10 | 1023 & e.charCodeAt(++a)),
                  c[r++] = 240 | n >> 18,
                  c[r++] = 128 | n >> 12 & 63,
                  c[r++] = 128 | n >> 6 & 63,
                  c[r++] = 128 | 63 & n);
      else
        for (r = this.start; a < o && r < 64; ++a)
          n = e.charCodeAt(a),
              n < 128 ? s[r >> 2] |= n << SHIFT[3 & r++] : n < 2048 ? (s[r >> 2] |= (192 | n >> 6) << SHIFT[3 & r++],
                  s[r >> 2] |= (128 | 63 & n) << SHIFT[3 & r++]) : n < 55296 || n >= 57344 ? (s[r >> 2] |= (224 | n >> 12) << SHIFT[3 & r++],
                  s[r >> 2] |= (128 | n >> 6 & 63) << SHIFT[3 & r++],
                  s[r >> 2] |= (128 | 63 & n) << SHIFT[3 & r++]) : (n = 65536 + ((1023 & n) << 10 | 1023 & e.charCodeAt(++a)),
                  s[r >> 2] |= (240 | n >> 18) << SHIFT[3 & r++],
                  s[r >> 2] |= (128 | n >> 12 & 63) << SHIFT[3 & r++],
                  s[r >> 2] |= (128 | n >> 6 & 63) << SHIFT[3 & r++],
                  s[r >> 2] |= (128 | 63 & n) << SHIFT[3 & r++]);
      this.lastByteIndex = r,
          this.bytes += r - this.start,
          r >= 64 ? (this.start = r - 64,
              this.hash(),
              this.hashed = !0) : this.start = r
    }
    return this.bytes > 4294967295 && (this.hBytes += this.bytes / 4294967296 << 0,
        this.bytes = this.bytes % 4294967296),
        this
  }
}

Md5.prototype.finalize = function() {
  if (!this.finalized) {
    this.finalized = !0;
    var e = this.blocks
        , t = this.lastByteIndex;
    e[t >> 2] |= EXTRA[3 & t],
    t >= 56 && (this.hashed || this.hash(),
        e[0] = e[16],
        e[16] = e[1] = e[2] = e[3] = e[4] = e[5] = e[6] = e[7] = e[8] = e[9] = e[10] = e[11] = e[12] = e[13] = e[14] = e[15] = 0),
        e[14] = this.bytes << 3,
        e[15] = this.hBytes << 3 | this.bytes >>> 29,
        this.hash()
  }
}
Md5.prototype.hash = function() {
  var e, t, i, n, r, a, o = this.blocks;
  this.first ? (e = o[0] - 680876937,
      e = (e << 7 | e >>> 25) - 271733879 << 0,
      n = (-1732584194 ^ 2004318071 & e) + o[1] - 117830708,
      n = (n << 12 | n >>> 20) + e << 0,
      i = (-271733879 ^ n & (-271733879 ^ e)) + o[2] - 1126478375,
      i = (i << 17 | i >>> 15) + n << 0,
      t = (e ^ i & (n ^ e)) + o[3] - 1316259209,
      t = (t << 22 | t >>> 10) + i << 0) : (e = this.h0,
      t = this.h1,
      i = this.h2,
      n = this.h3,
      e += (n ^ t & (i ^ n)) + o[0] - 680876936,
      e = (e << 7 | e >>> 25) + t << 0,
      n += (i ^ e & (t ^ i)) + o[1] - 389564586,
      n = (n << 12 | n >>> 20) + e << 0,
      i += (t ^ n & (e ^ t)) + o[2] + 606105819,
      i = (i << 17 | i >>> 15) + n << 0,
      t += (e ^ i & (n ^ e)) + o[3] - 1044525330,
      t = (t << 22 | t >>> 10) + i << 0),
      e += (n ^ t & (i ^ n)) + o[4] - 176418897,
      e = (e << 7 | e >>> 25) + t << 0,
      n += (i ^ e & (t ^ i)) + o[5] + 1200080426,
      n = (n << 12 | n >>> 20) + e << 0,
      i += (t ^ n & (e ^ t)) + o[6] - 1473231341,
      i = (i << 17 | i >>> 15) + n << 0,
      t += (e ^ i & (n ^ e)) + o[7] - 45705983,
      t = (t << 22 | t >>> 10) + i << 0,
      e += (n ^ t & (i ^ n)) + o[8] + 1770035416,
      e = (e << 7 | e >>> 25) + t << 0,
      n += (i ^ e & (t ^ i)) + o[9] - 1958414417,
      n = (n << 12 | n >>> 20) + e << 0,
      i += (t ^ n & (e ^ t)) + o[10] - 42063,
      i = (i << 17 | i >>> 15) + n << 0,
      t += (e ^ i & (n ^ e)) + o[11] - 1990404162,
      t = (t << 22 | t >>> 10) + i << 0,
      e += (n ^ t & (i ^ n)) + o[12] + 1804603682,
      e = (e << 7 | e >>> 25) + t << 0,
      n += (i ^ e & (t ^ i)) + o[13] - 40341101,
      n = (n << 12 | n >>> 20) + e << 0,
      i += (t ^ n & (e ^ t)) + o[14] - 1502002290,
      i = (i << 17 | i >>> 15) + n << 0,
      t += (e ^ i & (n ^ e)) + o[15] + 1236535329,
      t = (t << 22 | t >>> 10) + i << 0,
      e += (i ^ n & (t ^ i)) + o[1] - 165796510,
      e = (e << 5 | e >>> 27) + t << 0,
      n += (t ^ i & (e ^ t)) + o[6] - 1069501632,
      n = (n << 9 | n >>> 23) + e << 0,
      i += (e ^ t & (n ^ e)) + o[11] + 643717713,
      i = (i << 14 | i >>> 18) + n << 0,
      t += (n ^ e & (i ^ n)) + o[0] - 373897302,
      t = (t << 20 | t >>> 12) + i << 0,
      e += (i ^ n & (t ^ i)) + o[5] - 701558691,
      e = (e << 5 | e >>> 27) + t << 0,
      n += (t ^ i & (e ^ t)) + o[10] + 38016083,
      n = (n << 9 | n >>> 23) + e << 0,
      i += (e ^ t & (n ^ e)) + o[15] - 660478335,
      i = (i << 14 | i >>> 18) + n << 0,
      t += (n ^ e & (i ^ n)) + o[4] - 405537848,
      t = (t << 20 | t >>> 12) + i << 0,
      e += (i ^ n & (t ^ i)) + o[9] + 568446438,
      e = (e << 5 | e >>> 27) + t << 0,
      n += (t ^ i & (e ^ t)) + o[14] - 1019803690,
      n = (n << 9 | n >>> 23) + e << 0,
      i += (e ^ t & (n ^ e)) + o[3] - 187363961,
      i = (i << 14 | i >>> 18) + n << 0,
      t += (n ^ e & (i ^ n)) + o[8] + 1163531501,
      t = (t << 20 | t >>> 12) + i << 0,
      e += (i ^ n & (t ^ i)) + o[13] - 1444681467,
      e = (e << 5 | e >>> 27) + t << 0,
      n += (t ^ i & (e ^ t)) + o[2] - 51403784,
      n = (n << 9 | n >>> 23) + e << 0,
      i += (e ^ t & (n ^ e)) + o[7] + 1735328473,
      i = (i << 14 | i >>> 18) + n << 0,
      t += (n ^ e & (i ^ n)) + o[12] - 1926607734,
      t = (t << 20 | t >>> 12) + i << 0,
      r = t ^ i,
      e += (r ^ n) + o[5] - 378558,
      e = (e << 4 | e >>> 28) + t << 0,
      n += (r ^ e) + o[8] - 2022574463,
      n = (n << 11 | n >>> 21) + e << 0,
      a = n ^ e,
      i += (a ^ t) + o[11] + 1839030562,
      i = (i << 16 | i >>> 16) + n << 0,
      t += (a ^ i) + o[14] - 35309556,
      t = (t << 23 | t >>> 9) + i << 0,
      r = t ^ i,
      e += (r ^ n) + o[1] - 1530992060,
      e = (e << 4 | e >>> 28) + t << 0,
      n += (r ^ e) + o[4] + 1272893353,
      n = (n << 11 | n >>> 21) + e << 0,
      a = n ^ e,
      i += (a ^ t) + o[7] - 155497632,
      i = (i << 16 | i >>> 16) + n << 0,
      t += (a ^ i) + o[10] - 1094730640,
      t = (t << 23 | t >>> 9) + i << 0,
      r = t ^ i,
      e += (r ^ n) + o[13] + 681279174,
      e = (e << 4 | e >>> 28) + t << 0,
      n += (r ^ e) + o[0] - 358537222,
      n = (n << 11 | n >>> 21) + e << 0,
      a = n ^ e,
      i += (a ^ t) + o[3] - 722521979,
      i = (i << 16 | i >>> 16) + n << 0,
      t += (a ^ i) + o[6] + 76029189,
      t = (t << 23 | t >>> 9) + i << 0,
      r = t ^ i,
      e += (r ^ n) + o[9] - 640364487,
      e = (e << 4 | e >>> 28) + t << 0,
      n += (r ^ e) + o[12] - 421815835,
      n = (n << 11 | n >>> 21) + e << 0,
      a = n ^ e,
      i += (a ^ t) + o[15] + 530742520,
      i = (i << 16 | i >>> 16) + n << 0,
      t += (a ^ i) + o[2] - 995338651,
      t = (t << 23 | t >>> 9) + i << 0,
      e += (i ^ (t | ~n)) + o[0] - 198630844,
      e = (e << 6 | e >>> 26) + t << 0,
      n += (t ^ (e | ~i)) + o[7] + 1126891415,
      n = (n << 10 | n >>> 22) + e << 0,
  i += (e ^ (n | ~t)) + o[14] - 1416354905,
  i = (i << 15 | i >>> 17) + n << 0,
  t += (n ^ (i | ~e)) + o[5] - 57434055,
  t = (t << 21 | t >>> 11) + i << 0,
  e += (i ^ (t | ~n)) + o[12] + 1700485571,
  e = (e << 6 | e >>> 26) + t << 0,
  n += (t ^ (e | ~i)) + o[3] - 1894986606,
  n = (n << 10 | n >>> 22) + e << 0,
  i += (e ^ (n | ~t)) + o[10] - 1051523,
  i = (i << 15 | i >>> 17) + n << 0,
  t += (n ^ (i | ~e)) + o[1] - 2054922799,
  t = (t << 21 | t >>> 11) + i << 0,
  e += (i ^ (t | ~n)) + o[8] + 1873313359,
  e = (e << 6 | e >>> 26) + t << 0,
  n += (t ^ (e | ~i)) + o[15] - 30611744,
  n = (n << 10 | n >>> 22) + e << 0,
  i += (e ^ (n | ~t)) + o[6] - 1560198380,
  i = (i << 15 | i >>> 17) + n << 0,
  t += (n ^ (i | ~e)) + o[13] + 1309151649,
  t = (t << 21 | t >>> 11) + i << 0,
  e += (i ^ (t | ~n)) + o[4] - 145523070,
  e = (e << 6 | e >>> 26) + t << 0,
  n += (t ^ (e | ~i)) + o[11] - 1120210379,
  n = (n << 10 | n >>> 22) + e << 0,
  i += (e ^ (n | ~t)) + o[2] + 718787259,
  i = (i << 15 | i >>> 17) + n << 0,
  t += (n ^ (i | ~e)) + o[9] - 343485551,
  t = (t << 21 | t >>> 11) + i << 0,
  this.first ? (this.h0 = e + 1732584193 << 0,
      this.h1 = t - 271733879 << 0,
      this.h2 = i - 1732584194 << 0,
      this.h3 = n + 271733878 << 0,
      this.first = !1) : (this.h0 = this.h0 + e << 0,
      this.h1 = this.h1 + t << 0,
      this.h2 = this.h2 + i << 0,
      this.h3 = this.h3 + n << 0)
}

Md5.prototype.hex = function() {
  this.finalize();
  var e = this.h0
      , t = this.h1
      , i = this.h2
      , n = this.h3;
  return HEX_CHARS[e >> 4 & 15] + HEX_CHARS[15 & e] + HEX_CHARS[e >> 12 & 15] + HEX_CHARS[e >> 8 & 15] + HEX_CHARS[e >> 20 & 15] + HEX_CHARS[e >> 16 & 15] + HEX_CHARS[e >> 28 & 15] + HEX_CHARS[e >> 24 & 15] + HEX_CHARS[t >> 4 & 15] + HEX_CHARS[15 & t] + HEX_CHARS[t >> 12 & 15] + HEX_CHARS[t >> 8 & 15] + HEX_CHARS[t >> 20 & 15] + HEX_CHARS[t >> 16 & 15] + HEX_CHARS[t >> 28 & 15] + HEX_CHARS[t >> 24 & 15] + HEX_CHARS[i >> 4 & 15] + HEX_CHARS[15 & i] + HEX_CHARS[i >> 12 & 15] + HEX_CHARS[i >> 8 & 15] + HEX_CHARS[i >> 20 & 15] + HEX_CHARS[i >> 16 & 15] + HEX_CHARS[i >> 28 & 15] + HEX_CHARS[i >> 24 & 15] + HEX_CHARS[n >> 4 & 15] + HEX_CHARS[15 & n] + HEX_CHARS[n >> 12 & 15] + HEX_CHARS[n >> 8 & 15] + HEX_CHARS[n >> 20 & 15] + HEX_CHARS[n >> 16 & 15] + HEX_CHARS[n >> 28 & 15] + HEX_CHARS[n >> 24 & 15]
}

Md5.prototype.toString = Md5.prototype.hex,
    Md5.prototype.digest = function() {
      this.finalize();
      var e = this.h0
          , t = this.h1
          , i = this.h2
          , n = this.h3;
      return [255 & e, e >> 8 & 255, e >> 16 & 255, e >> 24 & 255, 255 & t, t >> 8 & 255, t >> 16 & 255, t >> 24 & 255, 255 & i, i >> 8 & 255, i >> 16 & 255, i >> 24 & 255, 255 & n, n >> 8 & 255, n >> 16 & 255, n >> 24 & 255]
    }
Md5.prototype.array = Md5.prototype.digest,
    Md5.prototype.arrayBuffer = function() {
      this.finalize();
      var e = new ArrayBuffer(16)
          , t = new Uint32Array(e);
      return t[0] = this.h0,
          t[1] = this.h1,
          t[2] = this.h2,
          t[3] = this.h3,
          e
    }
Md5.prototype.buffer = Md5.prototype.arrayBuffer,
    Md5.prototype.base64 = function() {
      for (var e, t, i, n = "", r = this.array(), a = 0; a < 15; )
        e = r[a++],
            t = r[a++],
            i = r[a++],
            n += BASE64_ENCODE_CHAR[e >>> 2] + BASE64_ENCODE_CHAR[63 & (e << 4 | t >>> 4)] + BASE64_ENCODE_CHAR[63 & (t << 2 | i >>> 6)] + BASE64_ENCODE_CHAR[63 & i];
      return e = r[a],
          n += BASE64_ENCODE_CHAR[e >>> 2] + BASE64_ENCODE_CHAR[e << 4 & 63] + "==",
          n
    }
;
var sort = function(data) {
  let t = Object.keys(data).sort()
  let result = "";
  t.forEach((function (t) {
        if (null !== data[t] && void 0 !== data[t]) {
          if (typeof data[t] === "object") {
            result += JSON.stringify(data[t]);
          } else if (Array.isArray(data[t])) {
            result += data[t];
          } else if (typeof data[t] === "string") {
            result += data[t].trim();
          } else {
            result += data[t];
          }
        }

      }
  ))
  return result
}

</script>
<style>
.popover-ai-box.ant-popover {
  z-index: 9999;
  width: 760px;
  left: 200px!important;
  top: -8px!important;
}
.popover-ai-box.ant-popover .ant-popover-arrow {
  top: 12px;
}
.ai-wrap .ant-skeleton-title,
.ai-wrap .ant-skeleton-paragraph {
  width: 90%!important;
}
</style>
<style scoped src="@/assets/css/add.css">
</style>
<style scoped>
::v-deep .item-txt .ant-form-item-control-input-content,
::v-deep .peer-wrap .ant-form-item-control-input-content,
.peer-wrap {
  display: flex;
  align-items: center;
}

.right_text {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #33383D;
  line-height: 16px;
}

::v-deep .peer-wrap {
  justify-content: space-between;
}

::v-deep .addContent .peer-wrap .ant-form-item {
  flex: 1;
  /* width: 47.8%;
  min-width: 47.8%;
  max-width: 47.8%; */
  width: calc(50% - 14px);
  min-width: calc(50% - 14px);
  max-width: calc(50% - 14px);
}

.center_text {
  display: block;
  line-height: 32px;
  color: #33383D;
  margin: 0 8px 16px;
  width: 4px;
}

.tow-item-box {
  margin-bottom: 0;
}
.item-add-five-box2 {
  width: calc(100% - 162px);
  margin-left: 18px;
}
::v-deep .item-add-five-box .ant-form-item-label {
  width: 84px !important;
  padding-right: 10px !important;
}

.participant-warp {
  display: flex;
  align-items: center;
}

.participant-warp1 {
  display: flex;
  align-items: center;
}

.participant-warp .icon-add {
  margin-left: 12px;
  cursor: pointer;
  margin-bottom: 16px;
}

.icon-add:hover .blur-svg {
  fill: #507AFD;
}

.participant-warp .icon-del {
  margin-left: 10px;
  cursor: pointer;
  margin-bottom: 16px;
}

.icon-del:hover .blur-svg {
  fill: #507AFD;
}

::v-deep .upload-box .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
}

.upload-tip {
  height: 18px;
  font-size: 14px;
  font-weight: 400;
  color: #4F5B6E;
  line-height: 14px;
  margin-left: 16px;
}

.item-width-box.ant-form-item {
  flex: 1;
  width: 30% !important;
  min-width: 30% !important;
  max-width: 30% !important;
  margin-right: 8px;
}

::v-deep .addContent .select-center-box.ant-form-item {
  flex: 1;
  width: calc(100% - 30% - 74px) !important;
  min-width: calc(100% - 30% - 74px) !important;
  max-width: calc(100% - 30% - 74px) !important;
}

::v-deep .addContent .select-conter-box1.ant-form-item {
  flex: 1;
  width: calc(100% - 30% - 40px) !important;
  min-width: calc(100% - 30% - 40px) !important;
  max-width: calc(100% - 30% - 40px) !important;
}

.item-xing {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.add-custer-link:hover {
  color: #507AFD;
}

/**2.1需求样式 */
.ai-btn {
  border-radius: 4px;
  border: 1px solid #507AFD;
  color: #507AFD;
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  line-height: 17px;
  padding: 4px 12px 3px 11px;
  margin-left: 20px;
}
.ai-btn:hover {
  cursor: pointer;
  background: #507AFD;
  color: #FFFFFF;
}
.ai-img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.ai-icon-hover {
  display: none;
}
.ai-btn:hover .ai-icon {
  display: none;
}
.ai-btn:hover .ai-icon-hover {
  display: inline-block;
}
.synchronous-box {
  margin-left: 96px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-flow: wrap;
}
.synchronous-item {
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  padding: 16px 20px;
  margin-right: 20px;
  cursor: pointer;
  position: relative;
  margin-bottom: 16px;
}
.synchronous-item-one {
  margin-bottom: 9px;
}
.synchronous-logo {
  width: 76px;
  height: 28px;
  margin-right: 15px;
}
.synchronous-logo1 {
  width: 100px;
  height: 28px;
  margin-right: 15px;
}
.synchronous-logo2 {
  width: 82px;
  height: 28px;
  margin-right: 15px;
}

.synchronous-btn {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  line-height: 16px;
  padding: 4px 10px;
}
.synchronous-no-release {
  border-radius: 12px;
  color: #507AFD;
  background: rgba(80, 122, 253, 0.1);
}
.synchronous-release {
  border-radius: 12px;
  color: #999;
  background: rgba(144, 144, 144, 0.1);
}
.synchronous-item-two {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.synchronous-name-left {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #4F5B6E;
  line-height: 20px;
  margin-right: 19px;
  display: flex;
  align-items: center;
}
.synchronous-label {
  margin-right: 4px;
}
.synchronous-txt {
  width: 86px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.synchronous-status-blur {
  color: #507AFD;
}
.synchronous-status-green {
  color: #36B37E;
}
.synchronous-icon-no-duigou {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  background: url('../../assets/img/icon/no_duigou.png') no-repeat center center;
  background-size: 100% 100%;
}
.synchronous-icon-duigou {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  background: url('../../assets/img/icon/duigou.png') no-repeat center center;
  background-size: 100% 100%;
}

.recruit-tip {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #EE9F29;
  line-height: 20px;
}
.form-item-left {
  margin-right: 198px;
}

.form-item-right {
  flex: 1;
}
::v-deep .form-item-right .ant-form-item-control-input-content {
  display: flex;
  align-items: flex-start;
}
::v-deep .form-item-right .ant-form-item {
  margin-bottom: 0;
}

.popover-ai-box .ant-popover-inner-content {
  padding: 24px;
}
.ai-item-head {
  display: flex;
  justify-content: space-between;
}
::v-deep .ai-item-head .close-btn:hover {
  color: #507AFD;
}
.custorm-title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.left-line {
  margin-right: 10px;
  width: 3px;
  height: 15px;
  background: #507AFD;
  border-radius: 2px;
}
.popover-ai-box .information_title {
  margin-bottom: 0;
}
.information_title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  text-align: left;
  font-style: normal;
}
.ai-item-content {
  margin-left: 13px;
  margin-bottom: 24px;
  white-space: pre-wrap;
  max-height: 110px;
  overflow: hidden;
  overflow-y: scroll;
}
.ai-item-box {
  display: flex;
  justify-content: flex-end;
}
.ai-item-btn {
  background: #507AFD;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  padding: 5px 16px;
  border-radius: 4px;
  cursor: pointer;
}
.ai-item-btn:hover,
.ai-item-btn:focus,
.ai-item-btn:active {
  background: #476DE3;
  border-color: #476DE3;
  color: #fff;
}
.ai-line {
  border-top: 1px solid #DCDFE6;
  margin: 24px 0;
}
::v-deep .synchronous-box .ant-checkbox-wrapper {
  line-height: 32px;
}
.synchronous-box {
  position: relative;
}
::v-deep .synchronous-box .ant-form-item-explain {
  position: absolute;
  color: #ff4d4f;
  height: 16px;
  line-height: 16px;
  min-height: 16px;
  font-size: 12px;
  opacity: 1;
  top: 28px;
  min-width: 200px;
}

/**下载安装插件弹窗样式 */
::v-deep .plugins-warp .ant-modal-header {
  display: none;
}
::v-deep .plugins-warp .ant-modal-body {
  padding: 24px 24px 32px;
}
::v-deep .plugins-warp .ant-modal-footer {
  padding: 0 24px 20px;
  border-top: 0;
}
::v-deep .plugins-warp .tips-box-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.tips-box-left {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  display: flex;
  align-items: center;
}
.tips-box-right svg {
  cursor: pointer;
}
.tips-box-right svg:hover path {
  fill: #507AFD;
}
::v-deep .plugins-warp img.icon-img {
  width: 22px;
  height: 22px;
  margin-right: 12px;
}
::v-deep .plugins-warp .tips-box-txt {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #4F5B6E;
  line-height: 22px;
  text-align: justify;
  font-style: normal;
  margin-left: 34px;
}


</style>
